import { MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";
import React, { useEffect, useState } from "react";
import Switch from "react-bootstrap-switch";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useToasts } from "react-toast-notifications";
import { modules } from "../../../../constants/Utils";
import { request } from "../../../../utils/axios-utils";
import Select from "../../../layout/forms/Select";
import ButtonConfirmModalIcon from "../../../layout/modals/ButtonConfirmModalIcon";

const AddCourrier: React.FC<{
    show: boolean;
    majeurId: string;
    createMail: Function;
    setShowCourrierModal: Function;
}> = (props: any) => {
    const fields: object = { label: "label", type: "type", value: "label" };
    const { addToast } = useToasts();

    const [contactsSelected, setContactsSelected] = useState<Array<any>>([]);

    const [contractId, setContractID] = useState<any>(null);

    const [text, setText] = useState("");

    const [suiviChecked, setSuiviChecked] = useState<boolean>(false);
    const [showEditor, setShowEditor] = useState<boolean>(true);

    const [contactOptions, setContactOptions] = useState<Array<any>>([]);
    const [contractsOptions, setContractsOptions] = useState<Array<any>>([]);
    const [templateOption, setTemplateOption] = useState<any>(null);
    const [templateOptions, setTemplateOptions] = useState<Array<any>>([]);
    const [label, setLabel] = useState<string>("");

    const changeEditorText = (templateId: string) => {
        if (templateId == "") {
            setText("");
        } else {
            request({ addToast, url: `/api/mails/${templateId}` })
                .then(({ data }) => {
                    if (!data.body) {
                        setShowEditor(false);
                    } else {
                        setText(
                            data.body.replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;")
                        );
                        setShowEditor(true);
                    }
                })
                .catch();
        }
    };

    // Add or ...
    const addFilter = (element: any) => {
        let newContactOptions = contactsSelected;
        newContactOptions.push(element.itemData);
        setContactsSelected(contactsSelected);
    };

    // ... Remove contact
    const removeFilter = (element: any) => {
        let newContactOptions = contactsSelected;
        newContactOptions.splice(contactsSelected.indexOf(element._id), 1);
        setContactsSelected(contactsSelected);
    };

    useEffect(() => {
        if (props.show) {
            request({ addToast, url: "/api/mails/mailsSelect" })
                .then(({ data }) => {
                    setTemplateOptions(data);
                })
                .catch();
            request({
                addToast,
                url: `/api/majeurs/socials/${props.majeurId}`,
            })
                .then(({ data }) => {
                    const contracts: React.SetStateAction<any[]> = [];
                    [...data.rights, ...data.contracts].map((contract) => {
                        contracts.push({
                            value: contract?._id,
                            label: contract?.nature + " " + contract?.numero,
                        });
                    });
                    setContractsOptions(contracts);
                })
                .catch();
            request({
                addToast,
                url: `/api/majeurs/contacts-select/${props.majeurId}`,
            })
                .then(({ data }) => {
                    setContactOptions(data);
                })
                .catch();
        }
    }, [props.show]);

    return (
        <ButtonConfirmModalIcon
            id={"add_mails_modal"}
            className="add_mails_modal"
            show={props.show}
            setShowCourrierModal={props.setShowCourrierModal}
            onValidate={() => {
                props.createMail(contactsSelected, templateOption, label, {
                    majeur_id: props.majeurId,
                    contract_id: contractId ? contractId : null,
                    template_id: templateOption,
                    suivi: suiviChecked,
                    body_value: text.replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;"),
                    destinataire_option: contactsSelected,
                });
            }}
            iconClassName={"action-button"}
            icon="add"
            question="Créer un courrier | requête"
        >
            <div className="layout-space-around-center column full-width">
                <div className="page_content full-width">
                    <div className="column">
                        <div className="my-input-container">
                            <label htmlFor="Template">
                                Courrier | Requête Type :{" "}
                            </label>
                            <Select
                                value={templateOption}
                                className="Select"
                                options={templateOptions}
                                needDefaultValue={true}
                                onChange={(e) => {
                                    setLabel(
                                        templateOptions.find(
                                            (element) =>
                                                element.value == e.target.value
                                        ).label
                                    ),
                                        setTemplateOption(e.target.value),
                                        changeEditorText(e.target.value);
                                }}
                                otherOptions={{
                                    clearable: true,
                                    placeholder: "Template...",
                                    noResultsText: "Entrée inconnue...",
                                }}
                            />
                        </div>
                        <div className="mutil_select_container">
                            <div className="my-input-container">
                                <label>Destinataires :</label>
                                <MultiSelectComponent
                                    id="mtselement"
                                    className="flex-1"
                                    cssClass="multiselect"
                                    popupHeight="150px"
                                    value={contactsSelected}
                                    fields={fields}
                                    dataSource={contactOptions}
                                    placeholder="Choisissez un(des) contact(s)..."
                                    removed={(e) => removeFilter(e)}
                                    select={(e) => addFilter(e)}
                                />
                            </div>
                            <div style={{ marginTop: "1%" }}>
                                <Select
                                    id="Contracts"
                                    label="Contrat"
                                    value={contractId}
                                    className="flex-1"
                                    options={contractsOptions}
                                    needDefaultValue={true}
                                    onChange={(e) => {
                                        setContractID(e.target.value);
                                    }}
                                />
                            </div>
                            <div>
                                {showEditor && (
                                    <div className="">
                                        <ReactQuill
                                            theme="snow"
                                            value={text}
                                            onChange={setText}
                                            modules={modules}
                                            style={{
                                                backgroundColor: "#fff",
                                                color: "#000",
                                                maxHeight: 300,
                                                minHeight: 300,
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <Switch
                            onChange={() => setSuiviChecked(!suiviChecked)}
                            labelText="Suivi"
                            bsSize="small"
                            wrapperClass="wrapper"
                            onText="OUI"
                            onColor="success"
                            offColor="danger"
                            offText="NON"
                            value={suiviChecked}
                        />
                    </div>
                </div>
            </div>
        </ButtonConfirmModalIcon>
    );
};

export default AddCourrier;
