import React, { useEffect, useState } from "react";
import Switch from "react-bootstrap-switch";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { uploadLinkDoc } from "../../../../_api/S3";
import { capitalizeFirst, formatTelephone } from "../../../../constants/Utils";
import IContact from "../../../../interfaces/IContact";
import IProtectedAdult from "../../../../interfaces/IProtectedAdult";
import ISocial from "../../../../interfaces/ISocial";
import { request } from "../../../../utils/axios-utils";
import ArrowBack from "../../../layout/ArrowBack";
import InputPassword from "../../../layout/InputPassword";
import ItemTwoLines from "../../../layout/ItemTwoLines";
import Loader from "../../../layout/Loader";
import InputComponent from "../../../layout/forms/InputComponent";
import InputDatePicker from "../../../layout/forms/InputDatePicker";
import InputSwitch from "../../../layout/forms/InputSwitch";
import Textarea from "../../../layout/forms/Textarea";
import SelectWithSearch from "../../../layout/forms/selectWithSearch";
import IFile from "../Cloud/IFile";
import UploadDocument from "../UploadDocument";
import Autosuggest from "react-autosuggest";
import { ListSocialsOptions } from "../../../../constants/ListSocialsOptions";

const CreateUpdateSocial: React.FC = (props) => {
    const { majeurId }: { majeurId?: string } = useParams();
    const { socialId }: { socialId?: string } = useParams();
    /* FORM */
    const [nature, setNature] = useState<string>("");
    const [number, setNumber] = useState<string>("");
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [notes, setNotes] = useState<string>("");

    const [login, setLogin] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [modifiedPassword, setModifiedPassword] = useState<boolean>(false);
    const [websiteUrl, setWebsiteUrl] = useState<string>("");

    const [alertChecked, setAlertChecked] = useState<boolean>(false);
    const [alertTiming, setAlertTiming] = useState<number>(0);

    const [suggestions, setSuggestions] = useState([]);
    const [suggestionLabelsOperations, setSuggestionLabelsOperations] =
        useState<Array<any>>([]);

    /* With interface */
    const [majeur, setMajeur] = useState<IProtectedAdult>(null);
    const [social, setSocial] = useState<ISocial>();
    const [contact, setContact] = useState<IContact>();

    const [listContacts, setListContacts] = useState([]);

    const [isArchived, setIsArchived] = useState(false);

    const [filesSelected, setFilesSelected] = useState(null);
    const [files, setFiles] = useState<Array<string>>(null);
    const [fileList, setFileList] = useState<Array<IFile>>(null);

    const [
        suggestionsAutocompletionForNature,
        setSuggestionsAutocompletionForNature,
    ] = useState<any>([]);
    const [natureAutocompletionValue, setNatureAutocompletionValue] =
        useState<string>("");

    const { addToast } = useToasts();
    const history = useHistory();

    useEffect(() => {
        if (majeurId && !majeur) {
            request({ addToast, url: `/api/majeur/${majeurId}` })
                .then(({ data }) => {
                    setMajeur(data);
                })
                .catch();
            setSuggestionLabelsOperations([]);
            setListContacts([]);
        }

        if (majeurId && listContacts.length == 0) {
            request({ addToast, url: `/api/users/organisms` })
                .then(({ data }) => {
                    setListContacts(data);
                })
                .catch();
        }

        if (socialId) {
            request({ addToast, url: `/api/socials/${socialId}` })
                .then(({ data }) => {
                    setSocial(data);
                    setNumber(data.numero);
                    setNature(data.nature);
                    setNatureAutocompletionValue(data.nature);
                    setNotes(data.notes || "");
                    setAlertTiming(data.alarm_timing);
                    setStartDate(
                        data?.start_date
                            ? new Date(
                                  data?.start_date?.$date
                                      ? parseInt(
                                            data.start_date.$date.$numberLong
                                        )
                                      : data?.start_date
                              )
                            : null
                    );
                    setPassword(data.password);
                    setLogin(data.login);
                    setEndDate(
                        data?.end_date
                            ? new Date(
                                  data?.end_date?.$date
                                      ? parseInt(
                                            data.end_date.$date.$numberLong
                                        )
                                      : data?.end_date
                              )
                            : null
                    );
                    setWebsiteUrl(data.websiteUrl);
                    setContact(data.contact);
                    selectContactWithSearch({
                        value: data.contact._id,
                        label: data.contact.organisme,
                    });
                    setAlertChecked(data.alarm);
                    setFiles(data.files ?? []);
                    setIsArchived(data.is_archived || false);
                    if (data.files) {
                        request({
                            url: "/api/files/list/from-ids",
                            method: "POST",
                            data: { filesIds: data.files },
                        })
                            .then(({ data }) => {
                                setFileList(data);
                            })
                            .catch();
                    }
                })
                .catch();
        }
    }, []);

    const checkform = (social: any) => {
        if (social.alarm && !social.end_date) {
            addToast(
                "Une date de fin est nécessaire pour créer une alarme sur ce droit.",
                { appearance: "warning" }
            );
            return false;
        }

        if (nature === "" || number === "" || !contact) {
            addToast("Veuillez remplir les champs obligatoires.", {
                appearance: "warning",
            });
            return false;
        }
        return true;
    };

    const createSocial = (social: any) => {
        request({
            addToast,
            url: `/api/socials/create/${majeurId}`,
            method: "post",
            data: { social: social },
        })
            .then(({ data }) => {
                history.push(`/single_majeur/${majeurId}/social`);
                addToast("Le droit social a bien été créée.", {
                    appearance: "success",
                });
            })
            .catch();
    };

    const editSocial = (social: any) => {
        request({
            addToast,
            url: `/api/socials/${socialId}/edit/${majeurId}`,
            method: "post",
            data: { social: social },
        })
            .then(({ data }) => {
                history.push(`/single_majeur/${majeurId}/social`);
                addToast("Le droit social a bien été mise à jour.", {
                    appearance: "success",
                });
            })
            .catch();
    };

    const saveSocial = (e: any) => {
        e.preventDefault();
        const social = {
            nature: capitalizeFirst(nature.trim()),
            numero: number,
            contact: contact,
            start_date: startDate,
            end_date: endDate,
            notes: notes,
            password: password,
            modifiedPassword: modifiedPassword,
            websiteUrl: websiteUrl,
            login: login,
            alarm: alertChecked,
            alarm_timing: alertTiming,
            is_archived: isArchived,
            files: files,
        };

        if (checkform(social)) {
            if (!filesSelected) {
                createSocial(social);
                return;
            }
            const formData = new FormData();
            for (let i = 0; i < filesSelected.length; i++) {
                formData.append("documents[]", filesSelected[i]);
            }
            formData.append("to", "Droits Sociaux");
            formData.append("majeur_id", majeurId);

            uploadLinkDoc(addToast, formData)
                .then(({ data }) => {
                    social.files = social.files
                        ? social.files.concat(data.data.filesIds)
                        : data.data.filesIds;
                    createSocial(social);
                })
                .catch();
        }
    };

    const updateSocial = async (e: any) => {
        e.preventDefault();
        const social = {
            nature: nature,
            numero: number,
            contact: contact,
            start_date: startDate,
            end_date: endDate,
            notes: notes,
            password: password,
            modifiedPassword: modifiedPassword,
            websiteUrl: websiteUrl,
            login: login,
            alarm: alertChecked,
            alarm_timing: alertTiming,
            is_archived: isArchived,
            files: files,
        };

        if (checkform(social)) {
            if (!filesSelected) {
                editSocial(social);
                return;
            }

            const formData = new FormData();
            for (let i = 0; i < filesSelected.length; i++) {
                formData.append("documents[]", filesSelected[i]);
            }
            formData.append("to", "Social");
            formData.append("majeur_id", majeurId);

            uploadLinkDoc(addToast, formData)
                .then(({ data }) => {
                    social.files = social.files.concat(data.data.filesIds);
                    editSocial(social);
                })
                .catch();
        }
    };

    const decryptPassword = () => {
        if (!isNew && !modifiedPassword) {
            request({
                addToast,
                url: `/api/socials/password/${socialId}`,
            })
                .then(({ data }) => {
                    setPassword(data);
                })
                .catch();
        }
    };

    const renderSectionTitle = (section: any) => {
        return <h5 className="section_autosuggest_nature">{section.value}</h5>;
    };

    const getSectionSuggestions = (section: any) => {
        return section.value;
    };

    const getSuggestionValueNatureAutocompletion = (nature: any) => {
        setNature(nature.nature);
        return `${nature.nature}`;
    };

    const getSuggestionsNatureAutocompletion = (value: any, natures: any) => {
        const escapedValue = value
            ? value.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
            : "";
        if (escapedValue === "") return [];
        const regex = new RegExp(escapedValue, "i");

        if (natures) {
            return natures
                .map((nature: any) => ({
                    nature: nature.label,
                }))
                .filter((nature: any) => regex.test(nature.nature));
        }

        return [];
    };

    const renderSuggestionNatureAutocompletion = (suggestion: any) => {
        if (suggestion) {
            return <span>{suggestion.nature}</span>;
        }
        return <span>{suggestion.nature}</span>;
    };

    const onChangeNatureAutocompletionValue = (newValue: any) => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }

        setNatureAutocompletionValue(newValue);
        setNature(newValue);
    };

    const onBlurBank = () => {};

    const selectContactWithSearch = (e: any) => {
        request({ addToast, url: `/api/contacts/${e.value}` })
            .then(({ data }) => {
                setContact(data);
            })
            .catch();
    };

    const title =
        majeur && window.screen.width > 600
            ? `${majeur.first_name} ${majeur.last_name} | `
            : "";

    const isNew = socialId === undefined;
    const shouldDisplayContent = social || isNew;

    return (
        <div className="Social">
            <ArrowBack
                title={`${title} ${
                    isNew ? "Ajouter" : "Modifier"
                } un droit social`}
            />
            <div className="page_content">
                {socialId && !social && <Loader />}
                {shouldDisplayContent && (
                    <form className="column">
                        <div className="row layout-xs-column flex-1">
                            <div>
                                <div className="flex-1 my-input-container">
                                    <label className="label">
                                        Nature :{" "}
                                        <span className="red_asterix"> *</span>
                                    </label>
                                </div>

                                <Autosuggest
                                    renderSectionTitle={(section: any) =>
                                        renderSectionTitle(section)
                                    }
                                    getSectionSuggestions={(section: any) =>
                                        getSectionSuggestions(section)
                                    }
                                    suggestions={
                                        suggestionsAutocompletionForNature
                                    }
                                    onSuggestionsFetchRequested={(
                                        section: any
                                    ) =>
                                        setSuggestionsAutocompletionForNature(
                                            getSuggestionsNatureAutocompletion(
                                                section.value,
                                                ListSocialsOptions
                                            )
                                        )
                                    }
                                    onSuggestionsClearRequested={() =>
                                        setSuggestionsAutocompletionForNature(
                                            []
                                        )
                                    }
                                    getSuggestionValue={(nature: any) =>
                                        getSuggestionValueNatureAutocompletion(
                                            nature
                                        )
                                    }
                                    renderSuggestion={(nature: any) =>
                                        renderSuggestionNatureAutocompletion(
                                            nature
                                        )
                                    }
                                    inputProps={{
                                        placeholder: "Nature...",
                                        value: natureAutocompletionValue,
                                        onChange: (
                                            event: any,
                                            section: any
                                        ) => {
                                            onChangeNatureAutocompletionValue(
                                                section.newValue
                                            );
                                        },
                                        onBlur: () => onBlurBank(),
                                    }}
                                />
                            </div>

                            <div className="flex005 hide-xs" />
                            <InputComponent
                                id="number"
                                className="flex-1"
                                label="Numéro"
                                required
                                value={number}
                                onChange={(e) => {
                                    setNumber(e.target.value);
                                }}
                            />
                            <div className="flex005 hide-xs" />

                            <SelectWithSearch
                                id="select_organisme"
                                label="Organisme"
                                className="flex-1"
                                needDefaultValue={true}
                                required
                                options={listContacts}
                                value={{
                                    value: contact?._id,
                                    label: contact?.organisme,
                                }}
                                onChange={(e) => selectContactWithSearch(e)}
                            />
                        </div>
                        <div className="row layout-xs-column flex-1">
                            <InputDatePicker
                                id="start_date"
                                label="Date de début"
                                onChange={(date: Date) => {
                                    setStartDate(date);
                                }}
                                inputDate={startDate}
                            />
                            <div className="flex005 hide-xs" />
                            <InputDatePicker
                                id="end_date"
                                label="Date de fin"
                                onChange={(date: Date) => {
                                    setEndDate(date);
                                }}
                                inputDate={endDate}
                            />
                        </div>
                        {social?.organisme && contact && !contact?._id && (
                            <div>
                                <ItemTwoLines
                                    title="Organisme"
                                    content={social?.organisme}
                                    flex="1"
                                />
                                <ItemTwoLines
                                    title="Adresse"
                                    content={
                                        social?.address ||
                                        social?.zip_code ||
                                        social?.city
                                            ? social?.address +
                                              " " +
                                              social?.zip_code +
                                              " " +
                                              social?.city
                                            : "Non renseigné."
                                    }
                                    flex="1"
                                />
                                <div className="row layout-xs-column">
                                    <ItemTwoLines
                                        title="Téléphone"
                                        content={
                                            social?.phone || "Non renseigné."
                                        }
                                        flex="1"
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="Email"
                                        content={
                                            social?.email || "Non renseigné."
                                        }
                                        flex="1"
                                    />
                                </div>
                                <ItemTwoLines
                                    title="Contact"
                                    content={
                                        social?.contact.first_name ||
                                        social?.contact.last_name
                                            ? social?.contact.civility +
                                              " " +
                                              social?.contact.first_name +
                                              " " +
                                              social?.contact.last_name
                                            : "Non renseigné."
                                    }
                                    flex="1"
                                />
                                <div style={{ marginBottom: 20 }} />
                            </div>
                        )}
                        {contact && contact?._id && (
                            <div
                                className="informations_generales_container"
                                style={{ padding: "0 5px" }}
                            >
                                {contact?.addresses &&
                                    contact?.addresses.length > 0 && (
                                        <div className="block_container">
                                            <h5>Adresses :</h5>
                                            <div className="layout-center-start column">
                                                {contact?.addresses.map((a) => (
                                                    <div
                                                        key={a.label}
                                                        className="items"
                                                    >
                                                        <h6>{a.label}.</h6>
                                                        <p className="address_zone">
                                                            {a.address}
                                                        </p>
                                                        <p>
                                                            {a.zip_code}{" "}
                                                            {a.city}
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                {contact.phones &&
                                    contact.phones.length > 0 && (
                                        <div className="block_container">
                                            <h5>Téléphones :</h5>
                                            <div className="layout-center-start column">
                                                {contact.phones.map((p) => (
                                                    <div
                                                        key={p.label}
                                                        className="items"
                                                    >
                                                        <h6>{p.label}.</h6>
                                                        <p>
                                                            {formatTelephone(
                                                                p.phone
                                                            )}
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                {contact.emails &&
                                    contact.emails.length > 0 && (
                                        <div className="block_container">
                                            <h5>Emails :</h5>
                                            <div className="layout-center-start column">
                                                {contact.emails.map((e) => (
                                                    <div
                                                        key={e.label}
                                                        className="items"
                                                    >
                                                        <h6>{e.label}.</h6>
                                                        <p>{e.email}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                            </div>
                        )}
                        <div
                            className="row layout-xs-column flex-1"
                            style={{ marginTop: "10px" }}
                        >
                            <InputComponent
                                id="website"
                                className="flex-1"
                                label="Lien site internet"
                                value={websiteUrl}
                                onChange={(e) => {
                                    setWebsiteUrl(e.target.value);
                                }}
                            />
                            <div className="flex005 hide-xs" />
                            <InputComponent
                                id={`AccelGerancia-${
                                    Math.floor(Math.random() * (1000 - 1)) + 1
                                }`}
                                className="flex-1"
                                label="Email ou nom utilisateur"
                                value={login}
                                onChange={(e) => {
                                    setLogin(e.target.value);
                                }}
                            />
                            <div className="flex005 hide-xs" />
                            <InputPassword
                                id={`passwordGerancia-${
                                    Math.floor(Math.random() * (1000 - 1)) + 1
                                }`}
                                className="flex-1"
                                label="Mot de passe"
                                autoComplete="off"
                                decryptFunction={decryptPassword}
                                value={password}
                                onChange={(e: any) => {
                                    setPassword(e.target.value);
                                    setModifiedPassword(true);
                                }}
                            />
                        </div>
                        <Textarea
                            id="notes"
                            label="Notes"
                            value={notes}
                            onChange={(e) => {
                                setNotes(e.target.value);
                            }}
                        />
                        <div className="row flex-1">
                            <div>
                                <Switch
                                    onChange={() => {
                                        setAlertChecked(!alertChecked);
                                    }}
                                    labelText="Alerte"
                                    bsSize="small"
                                    wrapperClass="wrapper"
                                    onText="OUI"
                                    onColor="success"
                                    offColor="danger"
                                    offText="NON"
                                    value={alertChecked}
                                />
                                <InputSwitch
                                    id="isArchived"
                                    onChange={() => setIsArchived(!isArchived)}
                                    label="Actifs"
                                    isSwitchMajeur={true}
                                    onText="NON"
                                    offText="OUI"
                                    value={isArchived}
                                />
                            </div>
                            <div className="flex005 hide-xs"></div>
                            {alertChecked && (
                                <div className="row">
                                    <InputComponent
                                        id="number"
                                        type="number"
                                        style={{ width: 60 }}
                                        label=""
                                        required
                                        minLength={0}
                                        maxLength={24}
                                        value={alertTiming}
                                        onChange={(e) =>
                                            setAlertTiming(e.target.value)
                                        }
                                    />
                                    <div
                                        style={{
                                            marginLeft: 5,
                                            marginTop: 10,
                                        }}
                                    >
                                        mois avant la date de fin
                                    </div>
                                </div>
                            )}
                        </div>

                        <div
                            className="divider"
                            style={{ marginBottom: "20px" }}
                        />

                        <UploadDocument
                            isMultiple={true}
                            filesSelected={filesSelected}
                            fileList={fileList}
                            setFilesSelected={setFilesSelected}
                            files={files}
                            setFiles={setFiles}
                            setFileList={setFileList}
                        />

                        <button
                            onClick={socialId ? updateSocial : saveSocial}
                            className="valid-button"
                        >
                            {isNew ? "Ajouter" : "Modifier"}
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default CreateUpdateSocial;
