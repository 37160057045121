import { MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { getExcelProtectedAdultList } from "../../../_api/documents";
import { filtersPA, yearsTable } from "../../../constants/Utils";
import IProtectedAdult from "../../../interfaces/IProtectedAdult";
import IUser from "../../../interfaces/IUser";
import { request } from "../../../utils/axios-utils";
import InputDatePicker from "../../layout/forms/InputDatePicker";
import Select from "../../layout/forms/Select";
import FicheAdminisrativeModal from "../../layout/modals/FicheAdministrativeModal";
import { generateFicheAdministrativeHTML } from "../Documents/FicheAdministrative";
import Help from "../Documents/Help";

const Documents: React.FC = () => {
    const yearOptions: Array<any> = yearsTable();
    const fields: object = { groupBy: "category", text: "value", value: "id" };
    const [selectObject, setSelectObject] = useState<any>();
    const [filtersSelected, setFilterSelected] = useState<any>({
        Nature: [],
        Localisation: [],
        Tribunal: [],
    });

    // All hooks to set/modify datas
    const [user, setUser] = useState<IUser>(null);
    const [majors, setMajors] = useState<Array<IProtectedAdult> | null>(null);
    const [MajeursFicheAdmin, setMajeursFicheAdmin] =
        useState<Array<any> | null>(null);
    const [majorsFilter, setMajorsFilter] = useState<string>("_COURANTS_");
    const [etatNominatif, setEtatNominatif] = useState<string>("1er trimestre"); // Not used for now
    const [selectedYear, setSelectedYear] = useState<string>(
        yearOptions[0].value
    );
    const [cerfaValue, setCerfaValue] = useState<string>("1");
    const [LoadRecipisse, setLoadRecipisse] = useState<string>("_OFF_");
    const [LoadDIPM, setLoadDIPM] = useState<string>("_OFF_");
    const [loadCerfaJudgeRequest, setLoadCerfaJudgeRequest] =
        useState<string>("_OFF_");
    const [loadCerfaApl, setLoadCerfaApl] = useState<string>("_OFF_");
    const [loadCerfaAspa, setLoadCerfaAspa] = useState<string>("_OFF_");
    const [loadCerfaMdph, setLoadCerfaMdph] = useState<string>("_OFF_");
    const [loadCerfaCss, setLoadCerfaCss] = useState<string>("_OFF_");
    const [
        loadCerfaRenonciationSuccession,
        setLoadCerfaRenonciationSuccession,
    ] = useState<string>("_OFF_");
    const [LoadRGPD, setLoadRGPD] = useState<string>("_OFF_");
    const [LoadCOVID, setLoadCOVID] = useState<string>("_OFF_");
    const [LoadProtectedAdultList, setProtectedAdultList] =
        useState<string>("_OFF_");
    const [LoadFicheAdministrative, setLoadFicheAdministrative] =
        useState<string>("_OFF_");
    const [LoadFicheAdministrativeDocx, setLoadFicheAdministrativeDocx] =
        useState<string>("_OFF_");
    const [ficheAdminExtension, setFicheAdminExtension] =
        useState<string>("pdf");
    const [LoadInventaire, setLoadInventaire] = useState<string>("_OFF_");
    const [LoadExportOCMI, setLoadExportOCMI] = useState<string>("_OFF_");
    const [LoadFicheIndiv, setLoadFicheIndiv] = useState<string>("_OFF_"); // Not used for now
    const [LoadEtatNominatif, setLoadEtatNominatif] = useState<string>("_OFF_"); // Not used for now
    const [LoadEtatNominatifNew, setLoadEtatNominatifNew] =
        useState<string>("_OFF_"); // Not used for now
    const [LoadCerfa, setLoadCerfa] = useState<string>("_OFF_");
    const [LoadAnnexe5, setLoadAnnexe5] = useState<string>("_OFF_");
    const [LoadAnnexe3, setLoadAnnexe3] = useState<string>("_OFF_");
    const [LoadAnnexe1, setLoadAnnexe1] = useState<string>("_OFF_");
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [majorsSelected, setMajorsSelected] = useState<boolean>(false);
    const [IsOpenFicheAdministrativeModal, setIsOpenFicheAdministrativeModal] =
        useState<boolean>(false);
    const disabledAnnexe1 =
        !majorsSelected || LoadAnnexe1 === "_ON_" || selectedYear === "2020";
    const disabledAnnexe3 =
        LoadAnnexe3 === "_ON_" ||
        selectedYear !== (new Date().getFullYear() - 1).toString();
    const disabledAnnexe5 = !majorsSelected || LoadAnnexe5 === "_ON_"; // || selectedYear.value === '2020';
    const [tribunaux, setTribunaux] = useState(null);
    const [showHelp, setShowHelp] = useState<boolean>(false);
    // Dates
    const [InventaireDate, setInventaireDate] = useState<Date>(new Date());

    const { addToast } = useToasts();

    useEffect(() => {
        if (!user && !majors) {
            request({ addToast, url: `/api/user` }).then(({ data }) => {
                setUser(data);
                request({ addToast, url: `/api/courts` })
                    .then(({ data }) => {
                        let startLength = filtersPA.length;
                        data.courts.map((court: any) => {
                            setTribunaux(data.courts);
                            startLength = startLength + 1;
                            filtersPA.push({
                                value: court.label,
                                category: "Tribunal",
                                id: startLength,
                            });
                        });
                    })
                    .catch();
                filterMajors("_COURANTS_", data._id);
            });
        }

        setSelectObject(document.getElementById("multiselect"));
    }, []);

    // Filtering PA by type
    const filterMajors = (value: string, userId?: string) => {
        setMajorsFilter(value);
        request({
            addToast,
            url: `/api/documents/${userId ? userId : user._id}/majors/${value}`,
        })
            .then(({ data }) => {
                setMajors(data.majors);
            })
            .catch();
    };

    // Add or ...
    const addFilter = (element: any) => {
        filtersSelected[element.itemData.category] = [
            ...filtersSelected[element.itemData.category],
            element.itemData.value,
        ];
        setFilterSelected(filtersSelected);
    };

    // ... Remove filter for exportation of PA list
    const removeFilter = (element: any) => {
        filtersSelected[element.itemData.category].splice(
            filtersSelected[element.itemData.category].indexOf(
                element.itemData.value
            ),
            1
        );
    };

    // To mark ALL PA as selected or not
    const onCheckSelectAllChange = () => {
        if (!checkAll) {
            for (let i = 0; i < selectObject.length; i += 1) {
                majors[i].selected = true;
                selectObject[i].selected = true;
            }
            setCheckAll(true);
            setMajorsSelected(true);
        } else {
            for (let i = 0; i < selectObject.length; i += 1) {
                majors[i].selected = false;
                selectObject[i].selected = false;
            }
            setCheckAll(false);
            setMajorsSelected(false);
        }
    };

    // To mark PA who are selected or not
    const setMajorSelected = (elements: any) => {
        for (let i = 0; i < elements.length; i++) {
            for (let k = 0; k < majors.length; k += 1) {
                if (majors[k]._id == elements[i].value) {
                    majors[k].selected = elements[i].selected;
                    if (majors[k].selected == true) {
                        setMajorsSelected(true);
                    }
                }
            }
        }
    };

    // Get the array of ids from selected majors
    const getSelectValuesFromMultiSelect = () => {
        const listMajorsId = [];
        for (let i = 0; i < majors.length; i += 1) {
            if (majors[i].selected) {
                listMajorsId.push(majors[i]._id);
            }
        }

        return listMajorsId;
    };

    // Download of DPIM
    const downloadDIPM = () => {
        const mIds = getSelectValuesFromMultiSelect();
        setLoadDIPM("_ON_");
        request({
            addToast,
            url: `/api/documents/dipm`,
            method: "post",
            data: {
                majorsId: mIds,
                user: user,
            },
        })
            .then((data) => {
                if (data.data.path) {
                    document.location.href = data.data.path;
                }
                setLoadDIPM("_OFF_");
            })
            .catch((data) => {
                setLoadDIPM("_OFF_");
            });
    };

    const downloadRGPD = () => {
        const mIds = getSelectValuesFromMultiSelect();
        setLoadRGPD("_ON_");
        request({
            addToast,
            url: `api/documents/download-rgpd`,
            method: "post",
            data: {
                majorsId: mIds,
            },
        })
            .then((data) => {
                setTimeout(() => {
                    if (data.data.path) {
                        document.location.href = data.data.path;
                    }
                    setLoadRGPD("_OFF_");
                }, 500);
            })
            .catch((data) => {
                setLoadRGPD("_OFF_");
            });
    };

    // Download of Protected Adults List
    const downloadProtectedAdultList = () => {
        const mIds = getSelectValuesFromMultiSelect();
        setProtectedAdultList("_ON_");
        getExcelProtectedAdultList(addToast, mIds, filtersSelected)
            .then((data) => {
                if (data.data.path) {
                    document.location.href = data.data.path;
                }
            })
            .catch();
        setProtectedAdultList("_OFF_");
    };

    // Download of Inventaire
    const downloadInventaire = (type: string) => {
        const mIds = getSelectValuesFromMultiSelect();
        setLoadInventaire("_ON_");
        request({
            addToast,
            url: "/api/documents/inventaire",
            method: "post",
            data: {
                majorsId: mIds,
                date: InventaireDate,
                user: user,
                doctype: type,
            },
        })
            .then((data) => {
                if (data.data.path) {
                    document.location.href = data.data.path;
                    setLoadInventaire("_OFF_");
                }
            })
            .catch((data) => {
                setLoadInventaire("_OFF_");
            });
    };

    // Download of Cerfa
    const downloadCerfa = () => {
        setLoadCerfa("_ON_");
        request({
            addToast,
            url: "/api/documents/cerfa",
            method: "post",
            data: {
                year: selectedYear,
                user: user,
                semestre: cerfaValue,
            },
        })
            .then((data) => {
                if (data.data.path) {
                    document.location.href = data.data.path;
                    setLoadCerfa("_OFF_");
                }
            })
            .catch((data) => {
                setLoadCerfa("_OFF_");
            });
    };

    const downloadCerfaJudgeRequest = () => {
        setLoadCerfaJudgeRequest("_ON_");
        request({
            addToast,
            url: "/api/documents/cerfa-requete",
            method: "post",
            data: {
                majorsId: getSelectValuesFromMultiSelect(),
            },
        })
            .then((data) => {
                if (data.data.path) {
                    document.location.href = data.data.path;
                    setLoadCerfaJudgeRequest("_OFF_");
                }
            })
            .catch((data) => {
                setLoadCerfaJudgeRequest("_OFF_");
            });
    };

    const downloadCerfaApl = () => {
        setLoadCerfaApl("_ON_");
        request({
            addToast,
            url: "/api/documents/cerfa-apl",
            method: "post",
            data: {
                majorsId: getSelectValuesFromMultiSelect(),
            },
        })
            .then((data) => {
                if (data.data.path) {
                    document.location.href = data.data.path;
                    setLoadCerfaApl("_OFF_");
                }
            })
            .catch((data) => {
                setLoadCerfaApl("_OFF_");
            });
    };

    const downloadCerfaRenonciationSuccession = () => {
        setLoadCerfaRenonciationSuccession("_ON_");
        request({
            addToast,
            url: "/api/documents/cerfa-renonciation-succession",
            method: "post",
            data: {
                majorsId: getSelectValuesFromMultiSelect(),
            },
        })
            .then((data) => {
                if (data.data.path) {
                    document.location.href = data.data.path;
                    setLoadCerfaRenonciationSuccession("_OFF_");
                }
            })
            .catch((data) => {
                setLoadCerfaRenonciationSuccession("_OFF_");
            });
    };

    const downloadCerfaAspa = () => {
        setLoadCerfaAspa("_ON_");
        request({
            addToast,
            url: "/api/documents/cerfa-aspa",
            method: "post",
            data: {
                majorsId: getSelectValuesFromMultiSelect(),
            },
        })
            .then((data) => {
                if (data.data.path) {
                    document.location.href = data.data.path;
                    setLoadCerfaAspa("_OFF_");
                }
            })
            .catch((data) => {
                setLoadCerfaAspa("_OFF_");
            });
    };

    const downloadCerfaCss = () => {
        setLoadCerfaCss("_ON_");
        request({
            addToast,
            url: "/api/documents/cerfa-css",
            method: "post",
            data: {
                majorsId: getSelectValuesFromMultiSelect(),
            },
        })
            .then((data) => {
                if (data.data.path) {
                    document.location.href = data.data.path;
                    setLoadCerfaCss("_OFF_");
                }
            })
            .catch((data) => {
                setLoadCerfaCss("_OFF_");
            });
    };

    const downloadCerfaMdph = () => {
        setLoadCerfaMdph("_ON_");
        request({
            addToast,
            url: "/api/documents/cerfa-mdph",
            method: "post",
            data: {
                majorsId: getSelectValuesFromMultiSelect(),
            },
        })
            .then((data) => {
                if (data.data.path) {
                    document.location.href = data.data.path;
                    setLoadCerfaMdph("_OFF_");
                }
            })
            .catch((data) => {
                setLoadCerfaMdph("_OFF_");
            });
    };

    // Download of Annexes (1, 3 or 5)
    const downloadAnnexe = (value: number) => {
        const mIds = getSelectValuesFromMultiSelect();
        switch (value) {
            case 1:
                setLoadAnnexe1("_ON_");
                //createAnnexe1;
                setLoadAnnexe1("_OFF_");
                break;
            case 3:
                setLoadAnnexe3("_ON_");
                request({
                    addToast,
                    url: "/api/documents/annex-three",
                    method: "post",
                    data: {
                        user: user,
                        year: selectedYear,
                    },
                })
                    .then((data) => {
                        if (data.data.path) {
                            document.location.href = data.data.path;
                        }
                    })
                    .catch();
                setLoadAnnexe3("_OFF_");
                break;
            case 5:
                setLoadAnnexe5("_ON_");
                //createAnnexe5;
                setLoadAnnexe5("_OFF_");
                break;
        }
    };

    // Download of OCMI
    const onSubmitExportOCMI = () => {
        const mIds = getSelectValuesFromMultiSelect();
        setLoadExportOCMI("_ON_");
        request({
            addToast,
            url: `/api/documents/ocmi`,
            method: "post",
            data: {
                majorsId: mIds,
                user: user,
            },
        })
            .then((data) => {
                const header = `id\ttribunal\tcivilité\tnomusuel\tnomnaissance\tdateNaissance\tlieuNaissance\tprénom1\tprénom2\tprénom3\tprénom4\tdatejugement\tdaterenouvellement\tnumeroregistre\tdateMission\tmission\tprotection\tlieuVie\tannée\t1.1\t1.2\t1.3\t1.4\t1.5\t1.6\t1.7\t1.8\t2.1\t2.2\t2.3\t2.4\t2.5\t2.6\t2.7\t2.8\t2.9\t3.1\t4.1\t4.2\t4.3\t4.4\t4.5\t5.1\t6.1\t7.1\t8.1\t9.1\tcodepostal\tville\r\n`;
                var blob = new Blob([header + data.data], {
                    type: "application/CSV;charset=utf-8",
                });
                saveAs(blob, "accegerancia-export-ocmi.csv");
                setLoadExportOCMI("_OFF_");
            })
            .catch((data) => {
                setLoadExportOCMI("_OFF_");
            });
    };

    // Download of recipissé
    const onSubmitRecipisse = () => {
        const mIds = getSelectValuesFromMultiSelect();
        setLoadRecipisse("_ON_");
        request({
            addToast,
            url: `/api/documents/recipisse`,
            method: "post",
            data: {
                majorsId: mIds,
                user: user,
            },
        })
            .then((data) => {
                if (data.data.path) {
                    document.location.href = data.data.path;
                }
                setLoadRecipisse("_OFF_");
            })
            .catch((data) => {
                setLoadRecipisse("_OFF_");
            });
    };

    //Open Administrative Modal
    const openFicheAdministrativeModal = () => {
        // Generate the infos for the documents
        const mIds = getSelectValuesFromMultiSelect();
        setLoadFicheAdministrative("_ON_");
        setLoadFicheAdministrativeDocx("_ON_");
        request({
            addToast,
            url: `/api/documents/ficheAdmin`,
            method: "post",
            data: {
                majorsId: mIds,
                user: user,
            },
        })
            .then((data) => {
                setMajeursFicheAdmin(data.data);
                setIsOpenFicheAdministrativeModal(true);
            })
            .catch((resp) => {
                console.log(resp.data.message);
                setLoadFicheAdministrative("_OFF_");
                setLoadFicheAdministrativeDocx("_OFF_");
            });
    };

    const downloadFicheAdministrative = async (
        fields: any,
        majeurs: Array<Object>,
        ids: { [unit: string]: string }
    ) => {
        if (MajeursFicheAdmin.length > 1) {
            let zip = new JSZip();
            let requests = MajeursFicheAdmin.map((m, index) => {
                const html = generateFicheAdministrativeHTML(m, fields, ids);

                return request({
                    addToast,
                    url: `/api/documents/ficheAdmin-docx`,
                    method: "post",
                    data: {
                        html: html,
                        format: ficheAdminExtension,
                    },
                    responseType: "blob",
                })
                    .then((data) => {
                        const fileName = `fiche_administrative_${
                            m.first_name + "_" + m.last_name
                        }.${ficheAdminExtension}`;
                        zip.file(fileName, data.data);
                    })
                    .catch((error) => {
                        console.log(error.response);
                        console.error("Something went wrong!", error);
                    });
            });

            Promise.all(requests)
                .then(() => {
                    zip.generateAsync({ type: "blob" }).then(function (
                        content
                    ) {
                        saveAs(content, "fiches_administratives.zip");
                    });
                })
                .then(() => {
                    setLoadFicheAdministrative("_OFF_");
                    setLoadFicheAdministrativeDocx("_OFF_");
                });
        }

        //Else we generate the unique doc
        else if (MajeursFicheAdmin.length == 1) {
            const html = generateFicheAdministrativeHTML(
                MajeursFicheAdmin[0],
                fields,
                ids
            );

            request({
                url: `/api/documents/ficheAdmin-docx`,
                method: "post",
                data: {
                    html: html,
                    format: ficheAdminExtension,
                },
                responseType: "blob",
            })
                .then((data) => {
                    saveAs(
                        data.data,
                        `fiche_administrative_${
                            MajeursFicheAdmin[0].first_name +
                            "_" +
                            MajeursFicheAdmin[0].last_name
                        }.${ficheAdminExtension}`
                    );
                    setLoadFicheAdministrative("_OFF_");
                    setLoadFicheAdministrativeDocx("_OFF_");
                })
                .catch((error) => {
                    addToast(
                        "Un caractère ne convient pas dans les informations de votre protégé. Veuillez nous contacter.",
                        {
                            appearance: "error",
                        }
                    );
                });
        }

        setIsOpenFicheAdministrativeModal(false);
    };

    // #region FUNCTIONS UNUSED FOR NOW

    // Download of Fiche Individuelle
    const onSubmitFicheIndividuelle = (version: number) => {
        // const mIds = getSelectValuesFromMultiSelect();
        // setLoadFicheIndiv('_ON_');
        // Make your api.php request here
        // setLoadFicheIndiv('_OFF_');
    };

    // Download of EtatNominatif
    const downloadEtatNominatif = (version: number) => {
        // const mIds = getSelectValuesFromMultiSelect();
        // setLoadEtatNominatif('_ON_');
        // Make your api.php request here
        // setLoadEtatNominatif('_OFF_');
    };

    // #endregion

    return (
        <div className="generator_documents">
            <div className="subBar_actions layout-center-center row">
                <span className="subBar_actions_title">
                    Générer vos documents
                </span>
            </div>
            <div className="page_content row layout-xs-column">
                <div className="flex-1 column">
                    <div className="row layout-space-between-center">
                        <div className="row checkbox_select_all_container">
                            <input
                                type="checkbox"
                                onChange={(e) => onCheckSelectAllChange()}
                            />
                            Tout sélectionner
                        </div>
                        <div style={{ width: 200 }}>
                            <Select
                                value={majorsFilter}
                                label=""
                                onChange={(e) => filterMajors(e.target.value)}
                                options={[
                                    { value: "_COURANTS_", label: "Courants" },
                                    { value: "_ARCHIVES_", label: "Archivés" },
                                    { value: "_TOUS_", label: "Tous" },
                                    {
                                        value: "_MPF_",
                                        label: "Mandat de protection future",
                                    },
                                    {
                                        value: "_MPF_ARCHIVED_",
                                        label: "Mandat de protection future archivé",
                                    },
                                ]}
                            />
                        </div>
                    </div>

                    <select
                        id="multiselect"
                        className="select_majeurs"
                        multiple
                        onChange={(e) => setMajorSelected(e.target)}
                    >
                        {majors &&
                            majors.map((m) => (
                                <option key={m._id} value={m._id}>
                                    {m.last_name} {m.first_name}
                                </option>
                            ))}
                    </select>
                </div>
                <div className="flex005 hide-xs"></div>
                <div className="flex-1 column button_container">
                    <div className="facturation_container block_doc">
                        <div className="title layout-center-center">
                            Administratif
                        </div>
                        <button
                            type="button"
                            className={
                                "notice_recipisse_button " +
                                (majorsSelected ? "" : "disabled")
                            }
                            disabled={
                                !majorsSelected || LoadRecipisse === "_ON_"
                            }
                            onClick={(e) => onSubmitRecipisse()}
                        >
                            Notice info et Récépissé
                            {LoadRecipisse === "_ON_" ? " En cours..." : ""}
                        </button>
                        <button
                            type="button"
                            className={
                                "notice_recipisse_button " +
                                (majorsSelected ? "" : "disabled")
                            }
                            disabled={!majorsSelected || LoadDIPM === "_ON_"}
                            onClick={(e) => downloadDIPM()}
                        >
                            DIPM
                            {LoadDIPM === "_ON_" ? " En cours..." : ""}
                        </button>
                        <button
                            type="button"
                            className={
                                "notice_recipisse_button " +
                                (majorsSelected ? "" : "disabled")
                            }
                            disabled={!majorsSelected || LoadRGPD === "_ON_"}
                            onClick={(e) => downloadRGPD()}
                        >
                            RGPD
                            {LoadRGPD === "_ON_" ? " En cours..." : ""}
                        </button>
                        {/* <button
                            type="button"
                            className={
                                "notice_recipisse_button " +
                                (majorsSelected ? "" : "disabled")
                            }
                            disabled={!majorsSelected || LoadCOVID === "_ON_"}
                            onClick={(e) => downloadCOVID()}
                        >
                            {LoadCOVID === "_ON_"
                                ? " En cours..."
                                : "Recueil du consentement COVID"}
                        </button> */}

                        <FicheAdminisrativeModal
                            majeurs={MajeursFicheAdmin}
                            show={IsOpenFicheAdministrativeModal}
                            message="Sélectionnez les champs"
                            onClose={() =>
                                setIsOpenFicheAdministrativeModal(false)
                            }
                            onValidate={(
                                fields: any,
                                infos: Array<Object>,
                                ids: { [unit: string]: string }
                            ) =>
                                downloadFicheAdministrative(fields, infos, ids)
                            }
                            params={{
                                styles: {
                                    height: "50vh",
                                    overflowY: "scroll",
                                },
                            }}
                        />

                        <button
                            type="button"
                            className={
                                "notice_recipisse_button " +
                                (majorsSelected ? "" : "disabled")
                            }
                            disabled={!majorsSelected || LoadDIPM === "_ON_"}
                            onClick={(e) => {
                                setFicheAdminExtension("pdf");
                                openFicheAdministrativeModal();
                            }}
                        >
                            Fiche administrative (PDF)
                            {LoadFicheAdministrative === "_ON_"
                                ? " En cours..."
                                : ""}
                        </button>

                        <button
                            type="button"
                            className={
                                "notice_recipisse_button " +
                                (majorsSelected ? "" : "disabled")
                            }
                            disabled={!majorsSelected || LoadDIPM === "_ON_"}
                            onClick={(e) => {
                                setFicheAdminExtension("docx");
                                openFicheAdministrativeModal();
                            }}
                        >
                            Fiche administrative (DOCX)
                            {LoadFicheAdministrativeDocx === "_ON_"
                                ? " En cours..."
                                : ""}
                        </button>

                        {showHelp && (
                            <div
                                className="row"
                                style={{
                                    width: "50%",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    textAlign: "center",
                                    marginBottom: "2%",
                                }}
                            >
                                <span>
                                    Le filtre vous permet de sélectionner
                                    la(les) Nature(s) et/ou Localisation(s) que
                                    vous souhaitez voir apparaitre dans l'export
                                    de la liste des protégés.
                                </span>
                            </div>
                        )}

                        <div className="row">
                            {tribunaux && (
                                <div
                                    className="col my-input-container"
                                    style={{
                                        width: "47.5%",
                                    }}
                                >
                                    <MultiSelectComponent
                                        id="mtselement"
                                        cssClass="multiselect"
                                        popupHeight="200px"
                                        fields={fields}
                                        dataSource={filtersPA}
                                        placeholder="Nature/Localisation/Tribunaux ..."
                                        removed={(e) => removeFilter(e)}
                                        select={(e) => addFilter(e)}
                                        children={
                                            <Help
                                                color="black"
                                                url="/pdf/TUTO_OCMI.pdf"
                                            />
                                        }
                                    />
                                </div>
                            )}
                            {tribunaux && (
                                <Help
                                    color="black"
                                    onlyText={true}
                                    displayed={showHelp}
                                    displayText={(value: boolean) =>
                                        setShowHelp(value)
                                    }
                                />
                            )}

                            <div
                                className="col my-input-container"
                                style={{ width: "50.5%", marginLeft: "2.8%" }}
                            >
                                <button
                                    type="button"
                                    className={
                                        "notice_recipisse_button " +
                                        (majorsSelected ? "" : "disabled")
                                    }
                                    disabled={!majorsSelected}
                                    onClick={(e) =>
                                        downloadProtectedAdultList()
                                    }
                                >
                                    Exporter liste des protégés
                                    {LoadProtectedAdultList === "_ON_"
                                        ? " En cours..."
                                        : ""}
                                </button>
                            </div>
                        </div>

                        <div className="flex005 hide-xs"></div>

                        <div className="row etat_nominatif_container layout-start-center">
                            <InputDatePicker
                                id="inventoryDate"
                                inputDate={new Date()}
                                required
                                onChange={(date: Date) =>
                                    setInventaireDate(date)
                                }
                            />
                            <div className="flex005 hide-xs"></div>
                            <div className="column">
                                <button
                                    type="button"
                                    className={
                                        "etat_nominatif_button " +
                                        (majorsSelected ? "" : "disabled")
                                    }
                                    disabled={
                                        !majorsSelected ||
                                        LoadInventaire == "_ON_"
                                    }
                                    onClick={(e) => downloadInventaire("xls")}
                                >
                                    Inventaire (XLS)
                                    {LoadInventaire == "_ON_"
                                        ? " En cours..."
                                        : ""}
                                </button>
                                <button
                                    type="button"
                                    className={
                                        "etat_nominatif_button " +
                                        (majorsSelected ? "" : "disabled")
                                    }
                                    disabled={
                                        !majorsSelected ||
                                        LoadInventaire == "_ON_"
                                    }
                                    onClick={(e) => downloadInventaire("pdf")}
                                >
                                    Inventaire PDF (PJ)
                                    {LoadInventaire == "_ON_"
                                        ? " En cours..."
                                        : ""}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="facturation_container block_doc">
                        <div className="title layout-center-center">
                            Facturation
                        </div>
                        <div className="flex005 hide-xs"></div>
                        <div className="row flex-1">
                            <button
                                type="button"
                                className={
                                    "fiche_indiv_button " +
                                    (majorsSelected ? "" : "disabled")
                                }
                                disabled={
                                    !majorsSelected || LoadExportOCMI === "_ON_"
                                }
                                onClick={(e) => onSubmitExportOCMI()}
                            >
                                Export OCMI
                                {LoadExportOCMI === "_ON_"
                                    ? " En cours..."
                                    : ""}
                            </button>

                            <div className={"layout-center-center"}>
                                <Help color="black" url="/pdf/TUTO_OCMI.pdf" />
                            </div>
                        </div>
                    </div>

                    <div className="cerfa_section_container block_doc">
                        <div className="title layout-center-center">Cerfa</div>
                        <div className="column layout-start-center flex-1">
                            <button
                                disabled={
                                    !majorsSelected ||
                                    loadCerfaJudgeRequest === "_ON_"
                                }
                                className={
                                    "cerfa_section_container_button " +
                                    (majorsSelected ? "" : "disabled")
                                }
                                onClick={(e) => downloadCerfaJudgeRequest()}
                            >
                                Cerfa requête au juge (14919*04)
                                {loadCerfaJudgeRequest == "_ON_"
                                    ? " En cours..."
                                    : ""}
                            </button>

                            <button
                                disabled={
                                    !majorsSelected || loadCerfaApl === "_ON_"
                                }
                                className={
                                    "cerfa_section_container_button " +
                                    (majorsSelected ? "" : "disabled")
                                }
                                onClick={(e) => downloadCerfaApl()}
                            >
                                Demande APL Caf/MSA (10840*07)
                                {loadCerfaApl == "_ON_" ? " En cours..." : ""}
                            </button>

                            <button
                                disabled={
                                    !majorsSelected ||
                                    loadCerfaRenonciationSuccession === "_ON_"
                                }
                                className={
                                    "cerfa_section_container_button " +
                                    (majorsSelected ? "" : "disabled")
                                }
                                onClick={(e) =>
                                    downloadCerfaRenonciationSuccession()
                                }
                            >
                                Demande Renonciation à succession (15828*05)
                                {loadCerfaRenonciationSuccession == "_ON_"
                                    ? " En cours..."
                                    : ""}
                            </button>

                            <button
                                disabled={
                                    !majorsSelected || loadCerfaAspa === "_ON_"
                                }
                                className={
                                    "cerfa_section_container_button " +
                                    (majorsSelected ? "" : "disabled")
                                }
                                onClick={(e) => downloadCerfaAspa()}
                            >
                                Demande ASPA (13710*03)
                                {loadCerfaAspa == "_ON_" ? " En cours..." : ""}
                            </button>

                            <button
                                disabled={
                                    !majorsSelected || loadCerfaCss === "_ON_"
                                }
                                className={
                                    "cerfa_section_container_button " +
                                    (majorsSelected ? "" : "disabled")
                                }
                                onClick={(e) => downloadCerfaCss()}
                            >
                                Demande CSS (52269#04)
                                {loadCerfaCss == "_ON_" ? " En cours..." : ""}
                            </button>

                            <button
                                disabled={
                                    !majorsSelected || loadCerfaMdph === "_ON_"
                                }
                                className={
                                    "cerfa_section_container_button " +
                                    (majorsSelected ? "" : "disabled")
                                }
                                onClick={(e) => downloadCerfaMdph()}
                            >
                                Demande MDPH (15692*01)
                                {loadCerfaMdph == "_ON_" ? " En cours..." : ""}
                            </button>
                        </div>
                    </div>
                    <div className="statistiques_container block_doc">
                        <div className="title layout-center-center">
                            Statistiques
                        </div>
                        <div className="row layout-start-center flex-1 layout-xs-column align_statistics_xs">
                            <div className="year_select_container">
                                <Select
                                    value={selectedYear}
                                    options={yearOptions}
                                    onChange={(e) =>
                                        setSelectedYear(e.target.value)
                                    }
                                />
                            </div>
                            <div className="flex005 hide-xs"></div>
                            <div className="column flex-1">
                                <div className="row cerfa_container layout-start-center">
                                    <div className="year_select_container">
                                        <Select
                                            value={cerfaValue}
                                            options={[
                                                {
                                                    value: "1",
                                                    label: "Semestre 1",
                                                },
                                                {
                                                    value: "2",
                                                    label: "Semestre 2",
                                                },
                                            ]}
                                            onChange={(e) =>
                                                setCerfaValue(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="flex005 hide-xs"></div>

                                    <button
                                        className={"cerfa_button"}
                                        disabled={LoadCerfa == "_ON_"}
                                        onClick={(e) => downloadCerfa()}
                                    >
                                        Cerfa
                                        {LoadCerfa == "_ON_"
                                            ? " En cours..."
                                            : ""}
                                    </button>
                                </div>
                                {/* {
                                    (user && user.type != '_ASSOCIATION_') &&
                                    (
                                        <button type="button"
                                            className={'annexe_button ' + (!disabledAnnexe5 ? '' : 'disabled')}
                                            disabled={disabledAnnexe5}
                                            onClick={(e) => downloadAnnexe(5)}
                                        >
                                            Annexe 5
                                            {LoadAnnexe5 === '_ON_' ? ' En cours...' : ''}
                                        </button>
                                    )
                                } */}
                                {user && user.type != "_ASSOCIATION_" && (
                                    <button
                                        type="button"
                                        className={
                                            "annexe1_button " +
                                            (!disabledAnnexe1 ? "" : "disabled")
                                        }
                                        disabled={disabledAnnexe1}
                                        onClick={(e) => downloadAnnexe(1)}
                                    >
                                        Annexe 1
                                        {LoadAnnexe1 === "_ON_"
                                            ? " En cours..."
                                            : ""}
                                    </button>
                                )}
                                {user && user.type != "_ASSOCIATION_" && (
                                    <button
                                        type="button"
                                        className={
                                            "annexe3_button " +
                                            (!disabledAnnexe3 ? "" : "disabled")
                                        }
                                        disabled={disabledAnnexe3}
                                        onClick={(e) => downloadAnnexe(3)}
                                    >
                                        Annexe 3
                                        {LoadAnnexe3 === "_ON_"
                                            ? " En cours..."
                                            : ""}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Documents;
