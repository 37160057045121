import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Button } from "../../../_shared/Button";
import { DefaultDropdown } from "../../../_shared/Dropdown";
import { InputForm } from "../../../_shared/InputField";
import { RedirectLink } from "../../../_shared/RedirectLink";
import { request } from "../../../utils/axios-utils";
import { useAuth } from "./auth.context";

export const RegisterCRG = (props) => {
    const { addToast } = useToasts();
    const history = useHistory();
    const { setCurrentUser } = useAuth();

    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [phone, setPhone] = useState<string>("");

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const [role, setRole] = useState("_VERIFICATEUR_CRG_");
    const [hash, setHash] = useState("");
    const [error, setError] = useState(null);

    const onKeyUp = (event) => {
        if (event.key === "Enter") {
            const element = document.getElementById("buttonLogin");
            element.click();
            submitRegistration();
        }
    };

    const submitRegistration = () => {
        const credentials = {
            type: role,
            label_association: undefined,
            civility: "N/A",
            first_name: firstName.trim(),
            last_name: lastName.trim(),
            phone: phone,
            hash: hash,
            email: email.trim().toLowerCase(),
            password: password.trim(),
            password2: password2.trim(),
        };

        request({
            addToast,
            url: "/api/register",
            method: "post",
            data: { credentials: credentials },
        })
            .then((data) => {
                addToast(
                    "Le compte a bien été créé." /*Validez votre email grâce au lien qui vous a été envoyé pour pouvoir commencer à utiliser Accel Gerancia.",*/,
                    { appearance: "success" }
                );
            })
            .catch();
        history.push("/connexion");
    };

    return (
        <div className="flex h-screen bg-gray-100">
            {/* Section Gauche avec Image et Texte */}
            <div className="w-1/2 relative">
                <img
                    id="logo"
                    src={"/imgs/logo_crg.webp"}
                    alt="Chateau"
                    className="w-full h-full object-cover"
                />

                <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-center p-10 text-white">
                    <h1
                        className="text-4xl font-bold cursor-pointer"
                        onClick={() => history.push("/connexion")}
                    >
                        Contrôle de Compte Rendu de Gestion (CRG)
                    </h1>
                    <p
                        className="text-lg mt-4 cursor-pointer"
                        onClick={() => history.push("/connexion")}
                    >
                        Par Accel Gerancia
                    </p>
                </div>
            </div>

            <div className="w-1/2 flex justify-center items-center">
                <div className="bg-white p-10 rounded-lg shadow-md w-3/4 max-w-md">
                    <div className="space-y-4">
                        <DefaultDropdown
                            name={"Je suis"}
                            options={[
                                {
                                    label: "Vérificateur de CRG",
                                    value: "_VERIFICATEUR_CRG_",
                                },
                                {
                                    label: "Personne controlée",
                                    value: "_DEPOT_CRG_",
                                },
                            ]}
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                        />
                        <InputForm
                            name="Email"
                            value={email}
                            set={setEmail}
                            placeholder={"example.name@epv.fr"}
                            required={true}
                        />

                        <InputForm
                            name="Nom"
                            value={lastName}
                            set={setLastName}
                            placeholder={"John"}
                            required={true}
                        />

                        <InputForm
                            name="Prénom"
                            value={firstName}
                            set={setFirstName}
                            placeholder={"Doe"}
                            required={true}
                        />
                        <InputForm
                            name="Téléphone"
                            value={phone}
                            set={setPhone}
                            placeholder={"00 00 00 00 00"}
                            required={true}
                        />
                        {role === "_DEPOT_CRG_" && (
                            <InputForm
                                name="Code d'activation"
                                value={hash}
                                set={setHash}
                                placeholder={"AZERTYU"}
                                required={true}
                            />
                        )}
                        <InputForm
                            type="password"
                            onKeyUp={onKeyUp}
                            name="Mot de passe"
                            value={password}
                            set={setPassword}
                            placeholder={"**********"}
                            required={true}
                        />
                        <InputForm
                            type="password"
                            onKeyUp={onKeyUp}
                            name="Vérification du mot de passe"
                            value={password2}
                            set={setPassword2}
                            placeholder={"**********"}
                            required={true}
                        />
                        <RedirectLink
                            to="/connexion"
                            name="Déjà un compte, se connecter"
                        />
                        {error && (
                            <p className="text-left text-red-500 text-xs italic">
                                {error}
                            </p>
                        )}
                        <div className="flex justify-end">
                            <Button
                                action={(e) => submitRegistration(e)}
                                name={"S'enregistrer"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
