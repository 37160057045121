import React, { useState } from "react";
import InputComponent from "./InputComponent";

const ListSubCategories: React.FC<{
    remove: Function;
    cancel: Function;
    editSubCategory: Function;
    updateSubCategory: Function;
    subCategoryInEdit: any;
    listOfSubCategories: Array<any>;
}> = (props) => {
    const [newSubCategoryValue, setNewSubCategoryValue] = useState<string>(
        props.subCategoryInEdit
    );

    return (
        <>
            <div className="sub_caterogires_container">
                {props.listOfSubCategories.map((l: any, index: number) => {
                    return (
                        !props.subCategoryInEdit &&
                        l !== props.subCategoryInEdit && (
                            <div
                                key={index}
                                className={`item_sub_category layout-space-between-center row ${
                                    !(index % 2) ? "even" : ""
                                }`}
                            >
                                <span>{l}</span>
                                <div>
                                    {![
                                        "Revenus fonciers",
                                        "Coupons",
                                        "Dividendes",
                                        "Intérêts",
                                        "Vente immobilière",
                                        "Vente mobilier",
                                        "Vente de titres",
                                        "Prime d'activités",
                                        "A.A.H.",
                                        "A.P.A.",
                                        "A.S.P.A.",
                                        "R.S.A.",
                                        "Fermage",
                                        "Pension de retraite",
                                        "Pension invalidité",
                                        "Pension alimentaire",
                                        "Pension militaire",
                                        "Rente viagère",
                                        "Rentes",
                                        "Salaire",
                                        "BIC",
                                        "BNC",
                                        "Gérant associés",
                                        "Revenu hors de france",
                                    ].includes(l) && (
                                        <>
                                            {" "}
                                            <i
                                                onClick={() => {
                                                    props.editSubCategory(l);
                                                    setNewSubCategoryValue(l);
                                                }}
                                                className="material-icons button-icon"
                                            >
                                                edit
                                            </i>
                                            <i
                                                onClick={() => props.remove(l)}
                                                className="material-icons button-icon"
                                            >
                                                delete
                                            </i>
                                        </>
                                    )}
                                </div>
                            </div>
                        )
                    );
                })}
                {props.subCategoryInEdit && (
                    <div className="editSubCategoryContainer">
                        <i
                            onClick={() => props.cancel()}
                            className="material-icons button-icon"
                        >
                            undo
                        </i>
                        <span className="editSubcategoryLabel">
                            Modification de la sous catégorie :{" "}
                            {props.subCategoryInEdit}
                        </span>
                        <div
                            className="editSubcategory"
                            onKeyDown={(
                                event: React.KeyboardEvent<HTMLInputElement>
                            ) => {
                                if (event.key === "Enter") {
                                    props.updateSubCategory(
                                        newSubCategoryValue,
                                        props.subCategoryInEdit
                                    );
                                    setNewSubCategoryValue(null);
                                }
                            }}
                        >
                            <div>
                                <InputComponent
                                    id="idSubCatEditing"
                                    className="my-input"
                                    required
                                    value={newSubCategoryValue}
                                    onChange={(e) =>
                                        setNewSubCategoryValue(e.target.value)
                                    }
                                    minLength={3}
                                    maxLength={50}
                                />
                                <span className="subcategoryEditButton">
                                    <button
                                        type="button"
                                        className="valid-button"
                                        onClick={() => {
                                            props.updateSubCategory(
                                                newSubCategoryValue,
                                                props.subCategoryInEdit
                                            );
                                            setNewSubCategoryValue(null);
                                        }}
                                    >
                                        Modifier
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
export default ListSubCategories;
