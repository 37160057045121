import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
    extractNumber,
    stringToFloat,
    yearsTable,
} from "../../../../constants/Utils";
import IProtectedAdult from "../../../../interfaces/IProtectedAdult";
import IRessource from "../../../../interfaces/IRessource";
import { request } from "../../../../utils/axios-utils";
import Loader from "../../../layout/Loader";
import InputComponent from "../../../layout/forms/InputComponent";
import Select from "../../../layout/forms/Select";

const CreateUpdateRessources: React.FC<{}> = (props) => {
    const { majeurId }: { majeurId: string } = useParams();
    const { year }: { year: string } = useParams();

    const [majeur, setMajeur] = useState<IProtectedAdult>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [yearSelected, setYearSelected] = useState<number>(parseInt(year));

    const [revenus_fonciers_bruts, setRevenus_fonciers_bruts] =
        useState<string>("0");
    const [
        benefices_industriels_commerciaux,
        setBenefices_industriels_commerciaux,
    ] = useState<string>("0");
    const [rem_gerant_associes, setRem_gerant_associes] = useState<string>("0");
    const [
        benefices_exploitation_agricole,
        setBenefices_exploitation_agricole,
    ] = useState<string>("0");
    const [traitement_salaires_pensions, setTraitement_salaires_pensions] =
        useState<string>("0");
    const [bene_profession_non_commercial, setBene_profession_non_commercial] =
        useState<string>("0");

    const [capitaux_mobiliers, setCapitaux_mobiliers] = useState<string>("0");
    const [plus_values_cession, setPlus_values_cession] = useState<string>("0");

    /* totalEpargne */
    const [livret_A, setLivret_A] = useState<string>("0");
    const [LEP, setLEP] = useState<string>("0");
    const [LES, setLES] = useState<string>("0");
    const [PEP, setPEP] = useState<string>("0");
    const [livret_jeune, setLivret_jeune] = useState<string>("0");
    const [LDD, setLDD] = useState<string>("0");
    const [epargne_logement, setEpargne_logement] = useState<string>("0");
    const [co_developpement, setCo_developpement] = useState<string>("0");
    const [epargne_actions, setEpargne_actions] = useState<string>("0");
    const [cel, setCel] = useState<string>("0");
    const [capital_extension, setCapital_extension] = useState<string>("0");
    const [codebis, setCodebis] = useState<string>("0");
    const [compte_a_terme, setCompte_a_terme] = useState<string>("0");
    const [csl, setCsl] = useState<string>("0");
    const [livret_bleu, setLivret_bleu] = useState<string>("0");
    const [pel, setPel] = useState<string>("0");
    const [perp, setPerp] = useState<string>("0");
    const [assurance_foret, setAssurance_foret] = useState<string>("0");

    /* totalBienNonProductifRevenu */
    const [capitaux_non_productif_revenus, setCapitaux_non_productif_revenus] =
        useState<string>("0");
    const [locative_immeubles_batis, setLocative_immeubles_batis] =
        useState<string>("0");
    const [
        valeur_locative_immeubles_non_batis,
        setValeur_locative_immeubles_non_batis,
    ] = useState<string>("0");
    const [assurance_vie, setAssurance_vie] = useState<string>("0");
    const [interessement_participation, setInteressement_participation] =
        useState<string>("0");

    /* totalAllocations */
    const [aah, setAah] = useState<string>("0");
    const [allocation_personnes_agees, setAllocation_personnes_agees] =
        useState<string>("0");
    const [minimum_vieillesse, setMinimum_vieillesse] = useState<string>("0");
    const [revenu_solidarite, setRevenu_solidarite] = useState<string>("0");
    const [prime_activite, setPrime_activite] = useState<string>("0");
    const [revenus_hors_france, setRevenus_hors_france] = useState<string>("0");

    /* CALCUL */
    const [totalRevenuImposable, setTotalRevenuImposable] = useState<number>(0);
    const [totalEpargne, setTotalEpargne] = useState<number>(0);
    const [totalBienNonProductifRevenu, setTotalBienNonProductifRevenu] =
        useState<number>(0);
    const [totalAllocations, setTotalAllocations] = useState<number>(0);
    const [totalTotal, setTotalTotal] = useState<number>(0);
    const [ressources, setRessources] = useState<IRessource>();

    const yearOptions: Array<any> = yearsTable();

    const history = useHistory();
    const { addToast } = useToasts();

    useEffect(() => {
        setIsLoading(true);
        if (!ressources) {
            /* GET ressources by YEAR */
            setValuesRessources(yearSelected);
        }
    }, [yearSelected, props]);

    const setValuesRessources = (year: number) => {
        request({
            addToast,
            url: `/api/majeurs/ressources/${majeurId}/${year}`,
        })
            .then(({ data }) => {
                setRessources(data);

                setRevenus_fonciers_bruts(data.revenus_fonciers_bruts ?? "0");
                setBenefices_industriels_commerciaux(
                    data.benefices_industriels_commerciaux ?? "0"
                );
                setRem_gerant_associes(data.rem_gerant_associes ?? "0");
                setTraitement_salaires_pensions(
                    data.traitement_salaires_pensions ?? "0"
                );
                setBenefices_exploitation_agricole(
                    data.benefices_exploitation_agricole ?? "0"
                );
                setCapitaux_mobiliers(data.capitaux_mobiliers ?? "0");
                setPlus_values_cession(data.plus_values_cession ?? "0");

                setLivret_A(data.livret_A ?? "0");
                setLEP(data.LEP ?? "0");
                setLES(data.LES ?? "0");
                setPEP(data.PEP ?? "0");
                setLDD(data.LDD ?? "0");
                setEpargne_logement(data.epargne_logement ?? "0");
                setCo_developpement(data.co_developpement ?? "0");
                setEpargne_actions(data.epargne_actions ?? "0");
                setAssurance_foret(data.assurance_foret ?? "0");
                setCapital_extension(data.capital_extension ?? "0");
                setCel(data.cel ?? "0");
                setPel(data.pel ?? "0");
                setCsl(data.csl ?? "0");
                setCompte_a_terme(data.compte_a_terme ?? "0");
                setCodebis(data.codebis ?? "0");
                setPerp(data.Perp ?? "0");
                setLivret_bleu(data.livret_bleu ?? "0");

                setCapitaux_non_productif_revenus(
                    data.capitaux_non_productif_revenus ?? "0"
                );
                setLocative_immeubles_batis(
                    data.locative_immeubles_batis ?? "0"
                );
                setValeur_locative_immeubles_non_batis(
                    data.valeur_locative_immeubles_non_batis ?? "0"
                );
                setAssurance_vie(data.assurance_vie ?? "0");
                setInteressement_participation(
                    data.interessement_participation ?? "0"
                );

                setBene_profession_non_commercial(
                    data.bene_profession_non_commercial ?? "0"
                );
                setAah(data.aah ?? "0");
                setAllocation_personnes_agees(
                    data.allocation_personnes_agees ?? "0"
                );
                setMinimum_vieillesse(data.minimum_vieillesse ?? "0");
                setRevenu_solidarite(data.revenu_solidarite ?? "0");
                setPrime_activite(data.prime_activite ?? "0");

                setRevenus_hors_france(data.revenus_hors_france ?? "0");

                setIsLoading(false);

                // Set le totalRevenuImposable
                let totalRevenuImposable =
                    (data.revenus_fonciers_bruts || 0) +
                    (data.benefices_industriels_commerciaux || 0) +
                    (data.rem_gerant_associes || 0) +
                    (data.benefices_exploitation_agricole || 0) +
                    (data.traitement_salaires_pensions || 0) +
                    (data.bene_profession_non_commercial || 0) +
                    (data.capitaux_mobiliers || 0) +
                    (data.plus_values_cession || 0);
                setTotalRevenuImposable(totalRevenuImposable);

                // Set le totalEpargne
                let totalEpargne =
                    (data.livret_A || 0) +
                    (data.LEP || 0) +
                    (data.LES || 0) +
                    (data.PEP || 0) +
                    (data.livret_jeune || 0) +
                    (data.LDD || 0) +
                    (data.epargne_logement || 0) +
                    (data.co_developpement || 0) +
                    (data.epargne_actions || 0) +
                    (data.capital_extension || 0) +
                    (data.codebis || 0) +
                    (data.cel || 0) +
                    (data.pel || 0) +
                    (data.csl || 0) +
                    (data.compte_a_terme || 0) +
                    (data.Perp || 0) +
                    (data.livret_bleu || 0);
                setTotalEpargne(totalEpargne);

                // Set le totalBienNonProductifRevenu
                let totalBienNonProductifRevenu =
                    (data.capitaux_non_productif_revenus || 0) * 0.03 +
                    (data.locative_immeubles_batis || 0) +
                    (data.valeur_locative_immeubles_non_batis || 0) +
                    (data.assurance_vie || 0) * 0.03 +
                    (data.interessement_participation || 0) * 0.03;
                setTotalBienNonProductifRevenu(totalBienNonProductifRevenu);

                // Set le totalAllocations
                let totalAllocations =
                    (data.aah || 0) +
                    (data.allocation_personnes_agees || 0) +
                    (data.minimum_vieillesse || 0) +
                    (data.revenu_solidarite || 0) +
                    (data.prime_activite || 0) +
                    (data.revenus_hors_france || 0);
                setTotalAllocations(totalAllocations);

                let totalTotal =
                    totalRevenuImposable +
                    totalEpargne +
                    totalBienNonProductifRevenu +
                    totalAllocations;

                setTotalTotal(totalTotal);
            })
            .catch();
    };

    const onSubmitUpdateRessoures = () => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }

        let newRessources = {
            majeur_id: majeurId,
            annee: Number(yearSelected),
            revenus_fonciers_bruts: Number(revenus_fonciers_bruts),
            benefices_industriels_commerciaux: Number(
                benefices_industriels_commerciaux
            ),
            rem_gerant_associes: Number(rem_gerant_associes),
            benefices_exploitation_agricole: Number(
                benefices_exploitation_agricole
            ),
            traitement_salaires_pensions: Number(traitement_salaires_pensions),
            bene_profession_non_commercial: Number(
                bene_profession_non_commercial
            ),
            capitaux_mobiliers: Number(capitaux_mobiliers),
            plus_values_cession: Number(plus_values_cession),
            livret_A: Number(livret_A),
            LEP: Number(LEP),
            LES: Number(LES),
            PEP: Number(PEP),
            livret_jeune: Number(livret_jeune),
            LDD: Number(LDD),
            epargne_logement: Number(epargne_logement),
            co_developpement: Number(co_developpement),
            epargne_actions: Number(epargne_actions),
            assurance_foret: Number(assurance_foret),
            capital_extension: Number(capital_extension),
            codebis: Number(codebis),
            cel: Number(cel),
            pel: Number(pel),
            csl: Number(csl),
            compte_a_terme: Number(compte_a_terme),
            Perp: Number(perp),
            livret_bleu: Number(livret_bleu),
            capitaux_non_productif_revenus: Number(
                capitaux_non_productif_revenus
            ),
            locative_immeubles_batis: Number(locative_immeubles_batis),
            valeur_locative_immeubles_non_batis: Number(
                valeur_locative_immeubles_non_batis
            ),
            assurance_vie: Number(assurance_vie),
            interessement_participation: Number(interessement_participation),
            aah: Number(aah),
            allocation_personnes_agees: Number(allocation_personnes_agees),
            minimum_vieillesse: Number(minimum_vieillesse),
            revenu_solidarite: Number(revenu_solidarite),
            prime_activite: Number(prime_activite),
            revenus_hors_france: Number(revenus_hors_france),
            total: Number(totalTotal),
        };

        // Update ressources object with new values
        if (ressources) {
            request({
                addToast,
                url: `/api/resources/${ressources._id}/edit`,
                method: "post",
                data: { resources: newRessources },
            })
                .then(({ data }) => {
                    addToast(
                        `Les ressources de l'année ${yearSelected} ont bien été mises à jour.`,
                        { appearance: "success" }
                    );
                    history.push(`/single_majeur/${majeurId}/ressources`);
                })
                .catch();
        }
        // Create a new ressources object
        else {
            request({
                addToast,
                url: `/api/resources/create`,
                method: "post",
                data: { resources: newRessources },
            })
                .then(({ data }) => {
                    addToast(
                        `Les ressources de l'année ${yearSelected} ont bien été créées.`,
                        { appearance: "success" }
                    );
                    history.push(`/single_majeur/${majeurId}/ressources`);
                })
                .catch();
        }
    };

    const calculateTotalRessource = () => {
        let totalRevenuImposable =
            (+revenus_fonciers_bruts || 0) +
            (+benefices_industriels_commerciaux || 0) +
            (+rem_gerant_associes || 0) +
            (+benefices_exploitation_agricole || 0) +
            (+traitement_salaires_pensions || 0) +
            (+bene_profession_non_commercial || 0) +
            (+capitaux_mobiliers || 0) +
            (+plus_values_cession || 0);

        let totalEpargne =
            (+livret_A || 0) +
            (+LEP || 0) +
            (+LES || 0) +
            (+PEP || 0) +
            (+livret_jeune || 0) +
            (+LDD || 0) +
            (+epargne_logement || 0) +
            (+co_developpement || 0) +
            (+epargne_actions || 0) +
            (+capital_extension || 0) +
            (+codebis || 0) +
            (+cel || 0) +
            (+pel || 0) +
            (+csl || 0) +
            (+compte_a_terme || 0) +
            (+perp || 0) +
            (+livret_bleu || 0);

        let totalBienNonProductifRevenu =
            (+capitaux_non_productif_revenus || 0) * 0.03 +
            (+locative_immeubles_batis || 0) +
            (+valeur_locative_immeubles_non_batis || 0) +
            (+assurance_vie || 0) * 0.03 +
            (+interessement_participation || 0) * 0.03;

        let totalAllocations =
            (+aah || 0) +
            (+allocation_personnes_agees || 0) +
            (+minimum_vieillesse || 0) +
            (+revenu_solidarite || 0) +
            (+prime_activite || 0) +
            (+revenus_hors_france || 0);

        let totalTotal =
            totalRevenuImposable +
            totalEpargne +
            totalBienNonProductifRevenu +
            totalAllocations;

        setTotalRevenuImposable(totalRevenuImposable);
        setTotalEpargne(totalEpargne);
        setTotalBienNonProductifRevenu(totalBienNonProductifRevenu);
        setTotalAllocations(totalAllocations);
        setTotalTotal(totalTotal);
    };

    let title;
    if (majeur && window.screen.width > 600) {
        title = `${majeur.first_name} ${majeur.last_name} | `;
    }

    return (
        <div className="update_ressources">
            <div className="arrow_back layout-start-center row">
                <i
                    className="material-icons"
                    onClick={() => window.history.back()}
                >
                    arrow_back
                </i>
                <div className="layout-center-center row full-width select_ressources_container">
                    <h3 className="title_update_ressources">
                        {title} Editer les ressources
                    </h3>
                    <Select
                        id="year_select"
                        value={yearSelected}
                        options={yearOptions}
                        onChange={(e: any) => {
                            setYearSelected(e.target.value);
                            setValuesRessources(e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="page_content">
                {isLoading && <Loader />}

                {!isLoading && (
                    <form
                        className="column" /*onSubmit={onSubmitUpdateRessoures}*/
                    >
                        {yearSelected < 2025 && (
                            <div className="ressources_rows_container">
                                <div className="layout-space-between-center row">
                                    <h4>Revenus imposable :</h4>
                                </div>
                                <div className="layout-space-between-center row back-color-item-odd">
                                    <span>
                                        Revenus Fonciers bruts :{" "}
                                        <span className="small">
                                            (déclaration 2044)
                                        </span>
                                    </span>
                                    <InputComponent
                                        id="revenus_fonciers_bruts"
                                        className="flex-1"
                                        value={revenus_fonciers_bruts}
                                        onChange={(e) => {
                                            setRevenus_fonciers_bruts(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setRevenus_fonciers_bruts(
                                                extractNumber(
                                                    revenus_fonciers_bruts
                                                )
                                            );
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row">
                                    <span>
                                        Bénéfices industriels et commerciaux :
                                    </span>
                                    <InputComponent
                                        id="benefices_industriels_commerciaux"
                                        className="flex-1"
                                        value={
                                            benefices_industriels_commerciaux
                                        }
                                        onChange={(e) => {
                                            setBenefices_industriels_commerciaux(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setBenefices_industriels_commerciaux(
                                                extractNumber(
                                                    benefices_industriels_commerciaux
                                                )
                                            );
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row back-color-item-odd">
                                    <span className="hide-xs">
                                        Rémunérations allouées aux gérants et
                                        associés :
                                    </span>
                                    <span className="hide show-xs">
                                        Rém. allouées aux gérants et associés :{" "}
                                    </span>
                                    <InputComponent
                                        id="rem_gerant_associes"
                                        className="flex-1"
                                        value={rem_gerant_associes}
                                        onChange={(e) => {
                                            setRem_gerant_associes(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setRem_gerant_associes(
                                                extractNumber(
                                                    rem_gerant_associes
                                                )
                                            );
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row">
                                    <span>
                                        Bénéfices de l&#039;exploitation
                                        agricole :{" "}
                                    </span>
                                    <InputComponent
                                        id="benefices_exploitation_agricole"
                                        className="flex-1"
                                        value={benefices_exploitation_agricole}
                                        onChange={(e) => {
                                            setBenefices_exploitation_agricole(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setBenefices_exploitation_agricole(
                                                extractNumber(
                                                    benefices_exploitation_agricole
                                                )
                                            );
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row back-color-item-odd">
                                    <span>
                                        Traitement, salaires, pensions et rentes
                                        viagères :{" "}
                                    </span>
                                    <InputComponent
                                        id="traitement_salaires_pensions"
                                        className="flex-1"
                                        value={traitement_salaires_pensions}
                                        onChange={(e) => {
                                            setTraitement_salaires_pensions(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setTraitement_salaires_pensions(
                                                extractNumber(
                                                    traitement_salaires_pensions
                                                )
                                            );
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row">
                                    <span>
                                        Bénéfices des professions non
                                        commerciales :{" "}
                                    </span>
                                    <InputComponent
                                        id="bene_profession_non_commercial"
                                        className="flex-1"
                                        value={bene_profession_non_commercial}
                                        onChange={(e) => {
                                            setBene_profession_non_commercial(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setBene_profession_non_commercial(
                                                extractNumber(
                                                    bene_profession_non_commercial
                                                )
                                            );
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row back-color-item-odd">
                                    <span>
                                        Revenus des capitaux mobiliers :{" "}
                                    </span>
                                    <InputComponent
                                        id="capitaux_mobiliers"
                                        className="flex-1"
                                        value={capitaux_mobiliers}
                                        onChange={(e) => {
                                            setCapitaux_mobiliers(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setCapitaux_mobiliers(
                                                extractNumber(
                                                    capitaux_mobiliers
                                                )
                                            );
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row">
                                    <span className="hide-xs">
                                        Plus-values de cession à titre onéreaux
                                        de biens ou de droit de toute nature :
                                    </span>
                                    <span className="hide show-xs">
                                        Plus-values de cession... :{" "}
                                    </span>
                                    <InputComponent
                                        id="plus_values_cession"
                                        className="flex-1"
                                        value={plus_values_cession}
                                        onChange={(e) => {
                                            setPlus_values_cession(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setPlus_values_cession(
                                                extractNumber(
                                                    plus_values_cession
                                                )
                                            );
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row">
                                    <span>TOTAL</span>
                                    <span className="update_sub_total">
                                        {(
                                            totalRevenuImposable || 0
                                        ).toLocaleString()}{" "}
                                        €
                                    </span>
                                </div>
                            </div>
                        )}
                        {yearSelected < 2025 && (
                            <div className="ressources_rows_container">
                                <div className="layout-space-between-center row">
                                    <h4>Epargne (intérêts) : </h4>
                                </div>
                                <div className="layout-space-between-center row back-color-item-odd">
                                    <span>Livret A : </span>
                                    <InputComponent
                                        id="livret_A"
                                        className="flex-1"
                                        value={livret_A}
                                        onChange={(e) => {
                                            setLivret_A(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setLivret_A(
                                                extractNumber(livret_A)
                                            );
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row">
                                    <span>LEP : </span>
                                    <InputComponent
                                        id="LEP"
                                        className="flex-1"
                                        value={LEP}
                                        onChange={(e) => {
                                            setLEP(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setLEP(extractNumber(LEP));
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row">
                                    <span>LES : </span>
                                    <InputComponent
                                        id="LES"
                                        className="flex-1"
                                        value={LES}
                                        onChange={(e) => {
                                            setLES(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setLES(extractNumber(LES));
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row back-color-item-odd">
                                    <span>PEP : </span>
                                    <InputComponent
                                        id="PEP"
                                        className="flex-1"
                                        value={PEP}
                                        onChange={(e) => {
                                            setPEP(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setPEP(extractNumber(PEP));
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row">
                                    <span>Livret jeune : </span>
                                    <InputComponent
                                        id="livret_jeune"
                                        className="flex-1"
                                        value={livret_jeune}
                                        onChange={(e) => {
                                            setLivret_jeune(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setLivret_jeune(
                                                extractNumber(livret_jeune)
                                            );
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row back-color-item-odd">
                                    <span>LDD : </span>
                                    <InputComponent
                                        id="LDD"
                                        className="flex-1"
                                        value={LDD}
                                        onChange={(e) => {
                                            setLDD(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setLDD(extractNumber(LDD));
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row">
                                    <span>Epargne logement : </span>
                                    <InputComponent
                                        id="epargne_logement"
                                        className="flex-1"
                                        value={epargne_logement}
                                        onChange={(e) => {
                                            setEpargne_logement(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setEpargne_logement(
                                                extractNumber(epargne_logement)
                                            );
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row back-color-item-odd">
                                    <span>Co développement : </span>
                                    <InputComponent
                                        id="co_developpement"
                                        className="flex-1"
                                        value={co_developpement}
                                        onChange={(e) => {
                                            setCo_developpement(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setCo_developpement(
                                                extractNumber(co_developpement)
                                            );
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row">
                                    <span>PEA : </span>
                                    <InputComponent
                                        id="epargne_actions"
                                        className="flex-1"
                                        value={epargne_actions}
                                        onChange={(e) => {
                                            setEpargne_actions(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setEpargne_actions(
                                                extractNumber(epargne_actions)
                                            );
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row back-color-item-odd">
                                    <span>Assurance Forêt : </span>
                                    <InputComponent
                                        id="assurance_foret"
                                        className="flex-1"
                                        value={assurance_foret}
                                        onChange={(e) => {
                                            setAssurance_foret(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setAssurance_foret(
                                                extractNumber(assurance_foret)
                                            );
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row">
                                    <span>Capital Extension : </span>
                                    <InputComponent
                                        id="captital_extension"
                                        className="flex-1"
                                        value={capital_extension}
                                        onChange={(e) => {
                                            setCapital_extension(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setCapital_extension(
                                                extractNumber(capital_extension)
                                            );
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row back-color-item-odd">
                                    <span>Codebis : </span>
                                    <InputComponent
                                        id="codebis"
                                        className="flex-1"
                                        value={codebis}
                                        onChange={(e) => {
                                            setCodebis(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setCodebis(extractNumber(codebis));
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row">
                                    <span>CEL : </span>
                                    <InputComponent
                                        id="CEL"
                                        className="flex-1"
                                        value={cel}
                                        onChange={(e) => {
                                            setCel(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setCel(extractNumber(cel));
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row back-color-item-odd">
                                    <span>PEL : </span>
                                    <InputComponent
                                        id="pel"
                                        className="flex-1"
                                        value={pel}
                                        onChange={(e) => {
                                            setPel(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setPel(extractNumber(pel));
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row">
                                    <span>CSL : </span>
                                    <InputComponent
                                        id="csl"
                                        className="flex-1"
                                        value={csl}
                                        onChange={(e) => {
                                            setCsl(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setCsl(extractNumber(csl));
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row back-color-item-odd">
                                    <span>Compte à terme : </span>
                                    <InputComponent
                                        id="compte_a_terme"
                                        className="flex-1"
                                        value={compte_a_terme}
                                        onChange={(e) => {
                                            setCompte_a_terme(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setCompte_a_terme(
                                                extractNumber(compte_a_terme)
                                            );
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row">
                                    <span>PERP : </span>
                                    <InputComponent
                                        id="perp"
                                        className="flex-1"
                                        value={perp}
                                        onChange={(e) => {
                                            setPerp(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setPerp(extractNumber(perp));
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row back-color-item-odd">
                                    <span>Livret Bleu : </span>
                                    <InputComponent
                                        id="livret_bleu"
                                        className="flex-1"
                                        value={livret_bleu}
                                        onChange={(e) => {
                                            setLivret_bleu(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setLivret_bleu(
                                                extractNumber(livret_bleu)
                                            );
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row">
                                    <span>TOTAL</span>
                                    <span className="update_sub_total">
                                        {(totalEpargne || 0).toLocaleString()} €
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className="ressources_rows_container">
                            <div className="layout-space-between-center row">
                                <h4>Bien non productif de revenu :</h4>
                            </div>
                            <div className="layout-space-between-center row back-color-item-odd">
                                <span>
                                    Capitaux non productif de revenu * :
                                </span>
                                <InputComponent
                                    id="capitaux_non_productif_revenus"
                                    className="flex-1"
                                    value={capitaux_non_productif_revenus}
                                    onChange={(e) => {
                                        setCapitaux_non_productif_revenus(
                                            stringToFloat(e.target.value)
                                        );
                                    }}
                                    needBlur={true}
                                    onBlur={() => {
                                        setCapitaux_non_productif_revenus(
                                            extractNumber(
                                                capitaux_non_productif_revenus
                                            )
                                        );
                                        calculateTotalRessource();
                                    }}
                                />
                            </div>
                            <div
                                style={{ height: 50 }}
                                className="layout-space-between-center row"
                            >
                                <div style={{ padding: 0 }}>
                                    <span>
                                        Valeur locative, Immeubles batis :
                                    </span>
                                    <br />
                                    <span
                                        style={{
                                            fontSize: 10,
                                            fontStyle: "italic",
                                        }}
                                    >
                                        mettez le montant inscrit sur la taxe
                                        foncière correspondant à « base » qui
                                        comprend l’abattement de 50%
                                    </span>
                                </div>
                                <InputComponent
                                    id="locative_immeubles_batis"
                                    className="flex-1"
                                    value={locative_immeubles_batis}
                                    onChange={(e) => {
                                        setLocative_immeubles_batis(
                                            stringToFloat(e.target.value)
                                        );
                                    }}
                                    needBlur={true}
                                    onBlur={() => {
                                        setLocative_immeubles_batis(
                                            extractNumber(
                                                locative_immeubles_batis
                                            )
                                        );
                                        calculateTotalRessource();
                                    }}
                                />
                            </div>
                            <div
                                style={{ height: 50 }}
                                className="layout-space-between-center row back-color-item-odd"
                            >
                                <div style={{ padding: 0 }}>
                                    <span>
                                        Valeur locative immeuble non batis :
                                    </span>
                                    <br />
                                    <span
                                        style={{
                                            fontSize: 10,
                                            fontStyle: "italic",
                                        }}
                                    >
                                        mettez le montant inscrit sur la taxe
                                        foncière correspondant à « base » qui
                                        comprend l’abattement de 20%
                                    </span>
                                </div>
                                <InputComponent
                                    id="valeur_locative_immeubles_non_batis"
                                    className="flex-1"
                                    value={valeur_locative_immeubles_non_batis}
                                    onChange={(e) => {
                                        setValeur_locative_immeubles_non_batis(
                                            stringToFloat(e.target.value)
                                        );
                                    }}
                                    needBlur={true}
                                    onBlur={() => {
                                        setValeur_locative_immeubles_non_batis(
                                            extractNumber(
                                                valeur_locative_immeubles_non_batis
                                            )
                                        );
                                        calculateTotalRessource();
                                    }}
                                />
                            </div>
                            <div className="layout-space-between-center row">
                                <span>Assurance vie * : </span>
                                <InputComponent
                                    id="assurance_vie"
                                    className="flex-1"
                                    value={assurance_vie}
                                    onChange={(e) => {
                                        setAssurance_vie(
                                            stringToFloat(e.target.value)
                                        );
                                    }}
                                    needBlur={true}
                                    onBlur={() => {
                                        setAssurance_vie(
                                            extractNumber(assurance_vie)
                                        );
                                        calculateTotalRessource();
                                    }}
                                />
                            </div>
                            <div className="layout-space-between-center row back-color-item-odd">
                                <span>Interessement participative * : </span>
                                <InputComponent
                                    id="interessement_participation"
                                    className="flex-1"
                                    value={interessement_participation}
                                    onChange={(e) => {
                                        setInteressement_participation(
                                            stringToFloat(e.target.value)
                                        );
                                    }}
                                    needBlur={true}
                                    onBlur={() => {
                                        setInteressement_participation(
                                            extractNumber(
                                                interessement_participation
                                            )
                                        );
                                        calculateTotalRessource();
                                    }}
                                />
                            </div>
                            <div className="layout-space-between-center row">
                                <span>TOTAL</span>
                                <span className="update_sub_total">
                                    {(
                                        totalBienNonProductifRevenu.toFixed(
                                            2
                                        ) || 0
                                    ).toLocaleString()}{" "}
                                    €
                                </span>
                            </div>
                        </div>
                        {yearSelected < 2025 && (
                            <div className="ressources_rows_container">
                                <div className="layout-space-between-center row">
                                    <h4>Allocations :</h4>
                                </div>
                                <div className="layout-space-between-center row back-color-item-odd">
                                    <span>A.A.H. :</span>
                                    <InputComponent
                                        id="aah"
                                        className="flex-1"
                                        value={aah}
                                        onChange={(e) => {
                                            setAah(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setAah(extractNumber(aah));
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row">
                                    <span>Allocation personnes agées</span>
                                    <InputComponent
                                        id="allocation_personnes_agees"
                                        className="flex-1"
                                        value={allocation_personnes_agees}
                                        onChange={(e) => {
                                            setAllocation_personnes_agees(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setAllocation_personnes_agees(
                                                extractNumber(
                                                    allocation_personnes_agees
                                                )
                                            );
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row back-color-item-odd">
                                    <span>Minimum viellesse :</span>
                                    <InputComponent
                                        id="minimum_vieillesse"
                                        className="flex-1"
                                        value={minimum_vieillesse}
                                        onChange={(e) => {
                                            setMinimum_vieillesse(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setMinimum_vieillesse(
                                                extractNumber(
                                                    minimum_vieillesse
                                                )
                                            );
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row">
                                    <span>Revenu solidarité : </span>
                                    <InputComponent
                                        id="revenu_solidarite"
                                        className="flex-1"
                                        value={revenu_solidarite}
                                        onChange={(e) => {
                                            setRevenu_solidarite(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setRevenu_solidarite(
                                                extractNumber(revenu_solidarite)
                                            );
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row back-color-item-odd">
                                    <span>Prime d'activité : </span>
                                    <InputComponent
                                        id="prime_activite"
                                        className="flex-1"
                                        value={prime_activite}
                                        onChange={(e) => {
                                            setPrime_activite(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setPrime_activite(
                                                extractNumber(prime_activite)
                                            );
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row">
                                    <span>Revenu hors France : </span>
                                    <InputComponent
                                        id="revenus_hors_france"
                                        className="flex-1"
                                        value={revenus_hors_france}
                                        onChange={(e) => {
                                            setRevenus_hors_france(
                                                stringToFloat(e.target.value)
                                            );
                                        }}
                                        needBlur={true}
                                        onBlur={() => {
                                            setRevenus_hors_france(
                                                extractNumber(
                                                    revenus_hors_france
                                                )
                                            );
                                            calculateTotalRessource();
                                        }}
                                    />
                                </div>
                                <div className="layout-space-between-center row back-color-item-odd">
                                    <span>TOTAL</span>
                                    <span className="update_sub_total">
                                        {(
                                            totalAllocations || 0
                                        ).toLocaleString()}{" "}
                                        €
                                    </span>
                                </div>
                            </div>
                        )}
                        {yearSelected < 2025 && (
                            <div className="ressources_rows_container">
                                <div className="layout-space-between-center row">
                                    <h4>TOTAL :</h4>
                                    <h4>
                                        {(totalTotal || 0).toLocaleString()} €
                                    </h4>
                                </div>
                            </div>
                        )}

                        <button
                            type="button"
                            onClick={() => onSubmitUpdateRessoures()}
                            className="valid-button"
                        >
                            Valider pour {yearSelected}
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
};
export default CreateUpdateRessources;
