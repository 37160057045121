import React from "react";

export default function FinancialTable({
    totalRI,
    totalEpargne,
    totalBnpr,
    totalAllRI,
    bigTotal,
}) {
    const formatCurrency = (value) =>
        value && !isNaN(value)
            ? value.toLocaleString("fr-FR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
              })
            : "0.00";

    return (
        <div className="flex justify-center items-center p-4">
            <div className="w-full max-w-md bg-white shadow-md rounded-lg overflow-hidden">
                <table className="w-full border-collapse">
                    <tbody>
                        {[
                            { label: "Revenus imposables", value: totalRI },
                            { label: "Epargne", value: totalEpargne },
                            {
                                label: "Biens non productifs de revenus",
                                value: totalBnpr,
                            },
                            { label: "Allocations + RI", value: totalAllRI },
                        ].map((item, index) => (
                            <tr
                                key={item.label}
                                className={
                                    index % 2 === 0 ? "bg-gray-100" : "bg-white"
                                }
                            >
                                <td className="px-4 py-2 font-semibold">
                                    {item.label}
                                </td>
                                <td className="px-4 py-2 text-right">
                                    {formatCurrency(item.value)} €
                                </td>
                            </tr>
                        ))}
                        <tr className="bg-gray-200 font-bold">
                            <td className="px-4 py-3">TOTAL</td>
                            <td className="px-4 py-3 text-right">
                                {formatCurrency(bigTotal)} €
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}
