import React from "react";

export default function FinancialDetailsTable({
    rows,
    additionalValue,
    secondTitle,
}) {
    const formatCurrency = (value) =>
        value && !isNaN(value)
            ? value.toLocaleString("fr-FR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
              })
            : "0.00";

    return (
        <div className="flex justify-between p-4">
            <div className="w-full bg-white shadow-md rounded-lg overflow-hidden">
                <table className="w-full border-collapse">
                    {/* En-tête de la table */}
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="px-4 py-2 font-semibold text-left">
                                Nom
                            </th>
                            {secondTitle && (
                                <th className="px-4 py-2 font-semibold text-left">
                                    {secondTitle}
                                </th>
                            )}
                            <th className="px-4 py-2 font-semibold text-right">
                                Montant
                            </th>
                            {additionalValue && (
                                <th className="px-4 py-2 font-semibold text-right">
                                    Montant
                                </th>
                            )}
                        </tr>
                    </thead>

                    {/* Corps de la table */}
                    <tbody>
                        {rows.map((item, index) => (
                            <tr
                                key={item.label}
                                className={
                                    index % 2 === 0 ? "bg-gray-100" : "bg-white"
                                }
                            >
                                <td className="px-4 py-2">{item.label}</td>
                                {secondTitle && (
                                    <td className="px-4 py-2">
                                        {item.sub_cat}
                                    </td>
                                )}
                                <td className="px-4 py-2 text-right">
                                    {formatCurrency(item.value)} €
                                </td>
                                {item.additionalValue !== undefined && (
                                    <td className="px-4 py-2 text-right">
                                        {formatCurrency(item.additionalValue)} €
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
