import React from "react";
import { useHistory } from "react-router-dom";
import { AlertMessage } from "../../../_shared/AlertMessage";

const ControlSelection = ({ validHash, currentControls, isProtected }) => {
    const history = useHistory();

    if (!validHash) return null;

    const controls = currentControls.filter(
        (control) => control.status === "WAITING_DEPOT"
    );
    return (
        <div
            className={
                isProtected
                    ? "w-full flex justify-center items-center"
                    : "w-1/2 flex justify-center items-center"
            }
        >
            <div className="bg-white p-10 rounded-lg shadow-md w-3/4 max-w-md">
                <div className="space-y-4">
                    <h3 className="text-center">
                        Choisir l'année de mon contrôle :
                    </h3>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-1">
                        {controls.map((control) => {
                            return (
                                <div
                                    key={control._id}
                                    className="cursor-pointer bg-white shadow-lg rounded-lg p-6 border border-gray-200"
                                    onClick={() =>
                                        history.push(
                                            `/verification/${control._id}`
                                        )
                                    }
                                >
                                    <div className="mt-4 bg-blue-50 border border-blue-300 rounded-md p-4">
                                        <p className="text-center text-lg font-semibold text-blue-900">
                                            {new Date(
                                                control.start_period
                                            ).toLocaleDateString()}{" "}
                                            -{" "}
                                            {new Date(
                                                control.end_period
                                            ).toLocaleDateString()}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {controls.length === 0 && (
                        <AlertMessage
                            type="info"
                            message="Il n'y a aucun contrôle en attente de dépot."
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ControlSelection;
