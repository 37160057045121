import moment from "moment";
import "moment/locale/fr";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { ToolBarAccountItems } from "../../../constants/ToolBarAccountItems";
import IAccount from "../../../interfaces/IAccount";
import IBank from "../../../interfaces/IBank";
import IProtectedAdult from "../../../interfaces/IProtectedAdult";
import IToolBarAccountItem from "../../../interfaces/IToolBarAccountItem";
import IUser from "../../../interfaces/IUser";
import { request } from "../../../utils/axios-utils";
import Icon from "../../layout/Icon";
import SubBar from "../../layout/SubBar";
import Modal from "../../layout/modals/Modal";
import InfosAccount from "../Accounts/InfosAccount";
import ListOperationsAccount from "../Accounts/ListOperationsAccount";
import ListRepetitionsAccount from "../Accounts/ListRepetitionsAccount";
import PrevisionAccount from "../Accounts/PrevisionAccount";

const SingleAccount: React.FC = () => {
    const history = useHistory();
    const { addToast } = useToasts();

    const { module }: { module?: string } = useParams();
    const [confirmDeletion, setConfirmDeletion] = useState<boolean>(false);
    const [moduleSelected, setModuleSelected] = useState<string>(
        module ? module : "infos"
    );
    const [majeur, setMajeur] = useState<IProtectedAdult>(null);
    const [account, setAccount] = useState<IAccount>(null);
    const [bank, setBank] = useState<IBank>(null);
    const [user, setUser] = useState<IUser>(null);
    const [fromItem, setFromItem] = useState<boolean>(false);

    const { accountId }: { accountId?: string } = useParams();

    useEffect(() => {
        if (!user) {
            request({ addToast, url: `/api/user` })
                .then(({ data }) => {
                    setUser(data);
                    request({
                        addToast,
                        url: `/api/accounts/${accountId}`,
                    }).then(({ data }) => {
                        setAccount(data.account);
                        setBank(data.bank);
                        setMajeur(data.majeur);
                    });
                })
                .catch();

            if (module) {
                setFromItem(true);
                setModuleSelected(module);
            }
        }
    }, [majeur, account]);

    useEffect(() => {
        request({ addToast, url: `/api/accounts/${accountId}` })
            .then(({ data }) => {
                setAccount(data.account);
                setBank(data.bank);
                setMajeur(data.majeur);
            })
            .catch();
    }, [accountId]);

    const getTitle = () => {
        let tempTitle = `${account.type} N° ${account.number}`;
        if (majeur && window.screen.width > 600) {
            tempTitle = `${majeur.first_name} ${majeur.last_name} | ${tempTitle}`;
        }
        if (account.updated_at) {
            tempTitle = ` ${tempTitle} | MAJ : ${moment(
                account?.sync_type === "ebics"
                    ? account.sync_at
                    : account.updated_at
            ).format("DD/MM/YYYY")}`;
        }
        if (account.closed) {
            tempTitle = `${tempTitle} | CLOTURÉ`;
        }

        return tempTitle;
    };

    const deleteAccount = () => {
        request({
            addToast,
            url: `/api/accounts/remove/${accountId}`,
            method: "post",
            data: {},
        })
            .then(({ data }) => {
                addToast("Le compte a bien été supprimé", {
                    appearance: "success",
                });
                history.push(`/single_majeur/${majeur._id}/comptes`);
            })
            .catch();
        setConfirmDeletion(false);
    };

    return (
        <>
            {majeur && account && (
                <div className="client">
                    <SubBar
                        title={getTitle()}
                        back={`/single_majeur/${majeur._id}/comptes`}
                        classNameTitle="black_color"
                    >
                        {" "}
                        {user && user?.roles[0] !== "CRG_VERIF" && (
                            <Link
                                to="#"
                                onClick={() => setConfirmDeletion(true)}
                            >
                                <Icon name="delete_forever" />
                            </Link>
                        )}
                    </SubBar>

                    <Modal
                        message="Attention : Voulez vous supprimer ce compte définitivement ?"
                        show={confirmDeletion}
                        onValidate={deleteAccount}
                    />

                    <div className="tool_bar_menu_container sticky top-40 no_print">
                        <div className="toolbar_menu layout-space-around-center row">
                            {ToolBarAccountItems.map(
                                (item: IToolBarAccountItem, index) => (
                                    <button
                                        onClick={() => {
                                            setModuleSelected(item._id),
                                                history.push(
                                                    `/single_account/${accountId}/${item._id}`
                                                );
                                        }}
                                        className={`toolbar_menu_item column pointer layout-center-center ${
                                            moduleSelected == item._id
                                                ? "selected"
                                                : ""
                                        }`}
                                    >
                                        <i className="material-icons">
                                            {item.icon}
                                        </i>
                                        <span className="title-action">
                                            {item.title}
                                        </span>
                                    </button>
                                )
                            )}
                        </div>
                    </div>

                    <div className="client_container">
                        {moduleSelected === "infos" && (
                            <InfosAccount
                                account={account}
                                majeurId={majeur._id}
                                majeur={majeur}
                                user={user}
                                bank={bank}
                            />
                        )}
                        {moduleSelected === "operations" && (
                            <ListOperationsAccount
                                account={account}
                                accountId={accountId}
                                majeurId={majeur._id}
                                majeur={majeur}
                                user={user}
                                fromItem={fromItem}
                            />
                        )}

                        {moduleSelected === "repetitions" &&
                            user &&
                            user?.roles[0] !== "CRG_VERIF" && (
                                <ListRepetitionsAccount
                                    accountId={accountId}
                                    majeurId={majeur._id}
                                    majeur={majeur}
                                />
                            )}
                        {moduleSelected === "previsionnel" && (
                            <PrevisionAccount
                                accountId={accountId}
                                majeurId={majeur._id}
                                majeur={majeur}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
export default SingleAccount;
